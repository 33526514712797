import React from 'react';
import SocialBar from './SocialBar.jsx';
import SiteLinks from './SiteLinks.jsx';
import { Link } from 'react-router-dom';

const Footer = () => {
  const copyrightYear = new Date().getFullYear();
  return (
    <footer className="flatrate-network-footer" id="footer">
      <div className="footer-desktop">
        <section className="footer-links container">
          <SocialBar />
          <SiteLinks />
        </section>
        <section className="footer-about">
          <div className="footer-title p-3 text-center">
            <p className="mt-2 mb-2">
              <strong>What is Flatrate Network?</strong>
            </p>
            <p>
              FlatRate Network is a way for moving companies to get booked jobs to perform, based on their own pricing
              and terms, in their local area.
              <br />
              Flatrate Network is US based and serves nationwide customers.
            </p>
          </div>
        </section>
        <section className="container">
          <div className="footer-legal-row">
            <Link className="logo-container mt-3" to="/" title="Flatrate Logo">
              <img
                alt="Flatrate Network Logo"
                className="logo"
                width="40px"
                height="40px"
                src={`${process.env.REACT_APP_CDN_IMAGES}/flatrateNetworkLogo40x40.jpg`}
              />
            </Link>
            <div className="legal-text-holder">
              <p className="legal-text text-center ">
                Flatrate Network is a Supplier Member of the American Moving &amp; Storage Association
                <br />
                ©Flatrate Network LLC {copyrightYear} &nbsp; 855-286-7258
                <br />
                <Link style={{ color: '#e23337' }} to="/privacy/">
                  Privacy policy
                </Link>
                <Link style={{ color: '#e23337' }} to="/agreement/">
                  &nbsp; Terms
                </Link>
                {/* <Link style={{color: '#e23337'}} to="/sitemap/">&nbsp; Site Map</Link>  */}
              </p>
            </div>
            <div className="trust-holder">
              <div className="d-flex">
                <img
                  alt="amsa logo"
                  className="logo amsa"
                  src={`${process.env.REACT_APP_CDN_IMAGES}/trustLogo_3.png`}
                />
                <img
                  alt="amsa logo"
                  className="logo amsa"
                  src={`${process.env.REACT_APP_CDN_IMAGES}/trustLogo_1.png`}
                />
              </div>
            </div>
          </div>
        </section>

        <style jsx>{`
          @media only screen and (min-width: 993px) {
            .footer-links {
              height: auto;
              border-bottom: 1px solid rgba(255, 255, 255, 0.06);
              padding-bottom: 50px;
              padding-top: 10px;
            }
          }

          @media only screen and (max-width: 992px) {
            .trust-holder,
            .legal-text-holder {
              display: none !important;
            }
          }

          .footer-desktop {
            background-color: #000000 !important;
            color: white;
          }

          .footer-about {
            border-top: 1px solid rgba(255, 255, 255, 0.06);
            border-bottom: 1px solid rgba(255, 255, 255, 0.06);
            font-size: 0.9rem;
          }
          .footer-title strong {
            font-size: 1.1rem !important;
          }
          .footer-legal-row {
            width: 100%;
            min-height: 80px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
          }
          .logo-container {
            flex-basis: 40%;
            float: left;
            height: 40px;
            width: 33.3333%;
          }
          .logo-container svg {
            height: 40px;
            margin-top: 20px;
            padding-left: 0.9375rem;
            padding-right: 0.9375rem;
          }
          .legal-text-holder {
            flex-basis: 55%;
            height: 80px;
            line-height: 80px;
            width: 33.3333%;
            display: inline-block;
            padding-left: 0.9375rem;
            padding-right: 0.9375rem;
          }
          .legal-text-holder p {
            display: inline-block;
            vertical-align: middle;
            padding: 0;
            margin: 0;
            line-height: 1.4em;
            font-size: 0.8em;
          }
          .trust-holder {
            flex-basis: 5%;
            line-height: 35px;
            margin-top: 20px;
          }
          .trust-holder img {
            margin-right: 3%;
          }

          .footer-title p {
            line-height: 1.2;
          }
        `}</style>
      </div>
    </footer>
  );
};

export default Footer;
