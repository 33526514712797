import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import has from 'lodash-es/has';
import { openModal } from '../../../redux/actions/modals';
import { Popover as BootstrapPopover } from 'reactstrap';
import { PopoverBody } from 'reactstrap';
import MobileOnly from '../../common/MobileOnly';
//TODO: fix mobile styling

const mapStateToProps = ({ auth, modals, ui, funnel }) => ({
  isLoggedIn: has(auth, 'user'),
  modalOpen: modals.length > 0,
  isMobile: ui.mobile.isMobile,
  currentStep: funnel.currentStep
});

const SaveItForLaterPop = props => {
  const [state, setState] = useState({
    iconHover: false,
    popoverVisible: false,
    tooltipHover: false
  });

  useEffect(() => {
    if(props.currentStep === "BOOK") {
      return
    } else if(!props.isLoggedIn) {
      let loginTimer = setTimeout(() =>{
        if(props.isMobile) openModal({name: 'saveItForLater'})
        else setState({ ...state, popoverVisible: true })
      }, 120000);
      return () => clearTimeout(loginTimer)
    }
    //eslint-disable-next-line
  },[]);


  const toggle = () => {
    setState({ ...state,  popoverVisible: !state.popoverVisible
    });
  }
  return (
    <>
      <div>
        <button
          id="saveItForLaterPop"
          className="gray-button save"
          onClick={() => props.openModal({ name: 'authModal', properties: { authModalView: 'register' } })}>
          {' '}
          Save{' '}
        </button>
        <BootstrapPopover
          placement="bottom"
          isOpen={state.popoverVisible && !props.modalOpen}
          target="saveItForLaterPop"
          toggle={toggle}>
          <PopoverBody>
            <div className="ns-popover-tooltip tooltip-content save-for-later">
              {/* // TODO: this stupid emoji looks gray on windows. Need to replace with an svg */}
              <button className="tooltip-close btn p-0 position-absolute" onClick={toggle}>
                ✖
              </button>
              <p className="tooltip-title m-0">Your move plan isn't saved!</p>
              <p className="tooltip-text pt-2">Please register to save your move plan.</p>
              <MobileOnly>
                <div className="actions clearfix">
                  <button className="primary-btn-small w-100" onClick={() => props.openModal({ name: 'authModal', properties: { authModalView: 'register' } })}>
                    Save
                  </button>
                </div>
              </MobileOnly>
            </div>
          </PopoverBody>
        </BootstrapPopover>
      </div>
      <style jsx>{ `
        .save {
          display: inline-block;
          height: 30px;
          width: 180px;
          overflow: hidden;
          padding: 0;
          line-height: 30px;
          color: #e23337 !important;
          text-shadow: none;
          border: 1px solid #e23337;
          font-style: none !important;
          margin-top: 10px;
          border-radius: 3px;
          width: 100px;
        }
        .tooltip-title {
          font-size: 14px;
          line-height: 24px;
          font-family: ProximaNovaBold,sans-serif;
        }
        .tooltip-text {
          font-size: 12px;
          line-height: 24px;
        }
        .save-for-later {
          background: url('${process.env.REACT_APP_CDN_IMAGES}/lamp.png') no-repeat #fff;
          background-size: 34px 44px;
          background-position: left center;
          position: relative;
          padding-left: 40px;
        }

        :global(.popover-body) {
          padding: 5px 0;
        }

        .tooltip-close {
          display: inline-block
        }
      `}</style>
    </>
  );
}

export default connect(
  mapStateToProps,
  { openModal }
)(SaveItForLaterPop);
