import React from 'react';
import { useWindowSize } from './Hooks';

const MobileOnly = ({ children }) => {
  let windowSize = useWindowSize();
  return windowSize.innerWidth <= 992
    ? <div className="mobile-only">
        {children}
      </div>
    : null
}


export default MobileOnly