import { NETWORK_SUCCESS, GET_SUGGESTIONS } from '../actionTypes';
import { apiRequest } from '../actions/api';
import { setSuggestions } from '../actions/common';

const compareMiddleware = ({ getState, dispatch }) => next => action => {
  next(action);
  if( action.type === GET_SUGGESTIONS) {
    dispatch(
      apiRequest({
        url: `${process.env.REACT_APP_API_URL}/mapbox/geocode`,
        method: 'GET',
        params: {
          search: action.payload.query,
          use_up: true,
          exact_address: action.payload.useExact
        },
        fromAction: {
          type: GET_SUGGESTIONS
        }
      })
    );
  }

  if (action.type === NETWORK_SUCCESS) {
    switch(action.meta.originalRequest.fromAction.type) {
      case GET_SUGGESTIONS: 
        dispatch(setSuggestions(action.payload.response.data))
        break;
      default:
        break;
    }
  }
}

export default compareMiddleware;