import { ACCEPT_JOB, DECLINE_JOB, NETWORK_SUCCESS, SET_PRICING_OVERRIDE, NETWORK_FAILURE } from '../actionTypes';
import { apiRequest } from '../actions/api';
import { setMoveplanLoading, getCurrentMoveplan } from '../actions/movePlan';
import { setMoverLoading, setJobDeclineInfo } from '../actions/mover';

const moverMiddleware = ({ getState, dispatch }) => next => action => {
  next(action);

  if(action.type === ACCEPT_JOB) {
    dispatch(setMoverLoading(true));
    dispatch(apiRequest({
      url: `api/v3/jobs/${getState().movePlan.currentPlan.job.id}/accept`,
      method: 'PATCH',
      fromAction: ACCEPT_JOB
    }))
  }

  if(action.type === DECLINE_JOB) {
    dispatch(setMoverLoading(true));
    dispatch(apiRequest({
      url: `api/v3/jobs/${getState().movePlan.currentPlan.job.id}/decline`,
      method: 'PATCH',
      params: {
        job: {
          mover_declined_reason_id: action.payload.mover_declined_reason_id,
          mover_declined_other_reason: action.payload.mover_declined_other_reason,
          mover_declined_comment: action.payload.mover_declined_comment
        }
      },
      fromAction: DECLINE_JOB
    }))
  }

  if(action.type === SET_PRICING_OVERRIDE) {
    dispatch(setMoveplanLoading(true));
    dispatch(apiRequest({
      url: `api/v3/jobs/${getState().movePlan.currentPlan.currentMPID}/set_override`,
      method: 'POST',
      params: action.payload,
      fromAction: SET_PRICING_OVERRIDE
    }))
  }

  if(action.type === NETWORK_SUCCESS) {
    switch (action.meta.originalRequest.fromAction) {
      case ACCEPT_JOB:
        dispatch(setMoverLoading(false));
        dispatch(getCurrentMoveplan());
        if(getState().auth.isMover) {
          setTimeout(() => window.location.href = `${process.env.REACT_APP_BASE_API_URL}/movers/${getState().currentPlan.mover.id}/dashboard`, 1000);
        }
        break;
      case DECLINE_JOB:
        dispatch(setMoverLoading(false));
        dispatch(setJobDeclineInfo(action.payload.response.data.job));
        dispatch(getCurrentMoveplan());
        if(getState().auth.isMover) {
          setTimeout(() => window.location.href = `${process.env.REACT_APP_BASE_API_URL}/movers/${getState().currentPlan.mover.id}/dashboard`, 1000);
        }
        break;
      case SET_PRICING_OVERRIDE:
        dispatch(setMoveplanLoading(true));
        break;
      default:
        break;
    }
  }

  if(action.type === NETWORK_FAILURE) {
    switch (action.meta.originalRequest.fromAction) {
      case ACCEPT_JOB:
        alert('Something went wrong. Please reload and try again.')
        dispatch(setMoverLoading(false));
        break;
      case DECLINE_JOB:
        alert('Something went wrong. Please reload and try again.')
        dispatch(setMoverLoading(false));
        break;
      case SET_PRICING_OVERRIDE:
        alert("Something went wrong with price override. Please refresh and try again. If this issue persists, contact support and we'll get to the bottom of things...");
        break;
      default:
        break;
    }
  }
};

export default moverMiddleware;