import React, { lazy, Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
// import { Route, Switch, Redirect } from "react-router-dom";
import { Route, Switch } from 'react-router-dom';
import { checkCookies } from '../../redux/actions/auth';
import Landing from '../landing/Landing';
import LandingMobileNewDesign from '../landing/LandingMobileNewDesign';
import LandingHourly from '../landing/LandingHourly';
import LandingHourlyPpcNyc from '../landing/LandingHourlyPpcNyc';
import LandingConcierge from '../landing/LandingConcierge';
import MobileOnly from '../common/MobileOnly';
import DesktopOnly from '../common/DesktopOnly';
import MobileHeader from '../common/MobileHeader/MobileHeader';
import Header from '../common/Header/Header';
import LoadingPage from '../common/LoadingPage';
import { useMedia } from '../common/Hooks';
import { useCookies } from 'react-cookie';
import { setCouponData } from '../../redux/actions/analytics';
import { useLocation } from 'react-router-dom';
import { removeParams } from '../../redux/middleware/analytics';

const AsyncSignIn = lazy(() => import('../auth/SignIn'));
const AsyncSignUp = lazy(() => import('../auth/SignUp'));
const AsyncSocialSignFailed = lazy(() => import('../auth/SocialSignFailed'));
const AsyncForgotPassword = lazy(() => import('../auth/ForgotPassword'));
const AsyncMovingServices = lazy(() => import('../marketing/MovingServices'));
const AsyncMovingCompaniesList = lazy(() => import('../cityPages/MovingCompaniesList'));
const AsyncCityPage = lazy(() => import('../cityPages/CityPage'));
const AsyncMoverPage = lazy(() => import('../moverPages/MoverPage'));
const AsyncMoversNearMe = lazy(() => import('../cityPages/MoversNearMe'));
const AsyncLongDistance = lazy(() => import('../marketing/LongDistance'));
const AsyncLocalMoving = lazy(() => import('../marketing/LocalMoving'));
const AsyncInternationalMoving = lazy(() => import('../marketing/InternationalMoving'));
const AsyncMovingTruckRental = lazy(() => import('../marketing/MovingTruckRental'));
const AsyncStorageServices = lazy(() => import('../marketing/StorageServices'));
const AsyncStorageWarehouse = lazy(() => import('../marketing/StorageWarehouse'));
const AsyncStorageSelf = lazy(() => import('../marketing/StorageSelf'));
const AsyncPressPage = lazy(() => import('../marketing/PressPage'));
const AsyncWhatIsFlatrateNetwork = lazy(() => import('../marketing/WhatIsFlatrateNetwork'));
const AsyncWhatIsIncluded = lazy(() => import('../marketing/WhatIsIncluded'));
const AsyncHowItWorks = lazy(() => import('../marketing/HowItWorks'));
const AsyncTestimonials = lazy(() => import('../marketing/Testimonials'));
const AsyncCustomerService = lazy(() => import('../marketing/CustomerService'));
const AsyncFaqPage = lazy(() => import('../marketing/FaqPage'));
const AsyncAgreement = lazy(() => import('../marketing/Agreement'));
const AsyncPrivacy = lazy(() => import('../marketing/PrivacyPolicy'));
const AsyncSitemap = lazy(() => import('../common/Footer/Sitemap'));
const AsyncInsurance = lazy(() => import('../marketing/Insurance'));
const AsyncBenefits = lazy(() => import('../marketing/Benefits'));
const AsyncPartnership = lazy(() => import('../marketing/Partnership'));
const AsyncForServiceProviders = lazy(() => import('../marketing/ForServiceProviders'));
const AsyncMoversFaq = lazy(() => import('../marketing/MoversFaq'));
const AsyncCorporateRelocation = lazy(() => import('../marketing/CorporateRelocation'));
const AsyncCorporateRelocationAbout = lazy(() => import('../marketing/CorporateRelocationAbout'));
const AsyncCorporateRelocationRegistration = lazy(() => import('../marketing/CorporateRelocationRegistration'));
const AsyncCorporateRelocationFaq = lazy(() => import('../marketing/CorporateRelocationFaq'));
const AsyncDeals = lazy(() => import('../marketing/Deals'));
const AsyncDnaOnly = lazy(() => import('../myHome/DnaOnly'));
const AsyncScholarship = lazy(() => import('../marketing/Scholarship'));
const AsyncCareers = lazy(() => import('../marketing/Careers'));
const AsyncFurnitureMoving = lazy(() => import('../marketing/FurnitureMoving'));
const AsyncHouseMovers = lazy(() => import('../marketing/HouseMovers'));
const AsyncProfessionalMovers = lazy(() => import('../marketing/ProfessionalMovers'));
const AsyncMembershipAgreement = lazy(() => import('../marketing/MembershipAgreement'));
const AsyncFlatrateNetworkPromise = lazy(() => import('../marketing/FlatrateNetworkPromise'));
const AsyncPartnerLanding = lazy(() => import('../landing/partnersLandingPage/PartnerLanding'));
const AsyncPartnerLandingHomeGauge = lazy(() => import('../landing/partnersLandingPage/PartnerLandingHomeGauge'));

const AsyncPageNotFound = lazy(() => import('../common/PageNotFound'));

const mapStateToProps = ({ router }) => ({
  params: router.location.search,
  path: router.location.path
});

const FullWidthLayout = props => {
  const [cookies, setCookie] = useCookies(['login_info']);
  let { params, checkCookies } = props;
  const queryParams = new URLSearchParams(useLocation().search);
  const moveid = queryParams.get('moveid');
  const frauth = queryParams.get('frauth');

  useEffect(() => {
    if (params.length > 0) {
      let searchParams = '';
      if (moveid || frauth) {
        searchParams = removeParams(queryParams);
      }
      if (searchParams.trim().length === 0) return;
      let p = JSON.parse(
        '{"' +
          decodeURI(searchParams.substring(1))
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      setCookie('react_funnel_utm_params', JSON.stringify(p), { domain: '.flatrate.com' });
      setCouponData(p);
    }
  }, [params, setCookie, moveid, frauth, queryParams]);
  useEffect(() => {
    checkCookies(cookies);
  }, [cookies, checkCookies]);

  const viewModel = useMedia(
    ['(min-width: 1440px)', '(min-width: 993px)', '(min-width: 768px)', '(min-width: 1px)'],
    ['wide', 'desktop', 'tablet', 'mobile'],
    'desktop'
  );

  const removeViewPort = () => {
    const elem = document.querySelectorAll('meta[name=viewport]')[0];
    elem.remove();
  };

  return (
    <div className="app-container">
      <DesktopOnly>
        <Header />
      </DesktopOnly>
      <MobileOnly>
        <MobileHeader />
      </MobileOnly>
      <Switch>
        <Route
          exact
          path="/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncForServiceProviders viewModel={viewModel} />
            </Suspense>
          )}
        />

        {/* <Route exact strict path="/:url*" render={() => <Redirect to={`${window.location.pathname}/`} />} /> */}
        {/* Forcing trailling slash at the end of site-wide URLs, moving/:id will not be affected  */}
        <Route exact path="/newmove" render={() => <Landing viewModel={viewModel} />} />
        <Route exact path="/mobile" render={() => <LandingMobileNewDesign viewModel={viewModel} />} />
        <Route exact path="/hourly" render={() => <LandingHourly viewModel={viewModel} />} />
        <Route exact path="/hourly2" render={() => <LandingHourlyPpcNyc viewModel={viewModel} />} />
        <Route exact path="/concierge" render={() => <LandingConcierge viewModel={viewModel} />} />
        <Route
          path="/clients/:partner/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <Landing viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          path="/arpg"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncPartnerLanding viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          path="/homegauge"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncPartnerLandingHomeGauge viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          path="/sign-in/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncSignIn viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          path="/sign-up/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncSignUp viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          path="/re-signup/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncSocialSignFailed viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          path="/forgot-password"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncForgotPassword viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          path="/myhome"
          component={() => {
            window.location.href = `${process.env.REACT_APP_SUPPORT_URL}/my-home`;
            return null;
          }}
        />

        <Route
          exact
          path="/moving-services/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncMovingServices viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/moving-companies/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncMovingCompaniesList viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          path="/moving-companies/:stateId/:cityId/"
          render={props => (
            <Suspense fallback={null}>
              <AsyncCityPage viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          path="/movers/:moverId"
          render={props => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncMoverPage viewModel={viewModel} match={props.match} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/movers-near-me/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncMoversNearMe viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/moving-long/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncLongDistance viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          path="/moving-long/:question/"
          render={props => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncLongDistance viewModel={viewModel} {...props} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/moving-local/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncLocalMoving viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/moving-international/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncInternationalMoving viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/moving-truck-rental/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncMovingTruckRental viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/storage-services/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncStorageServices viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/storage-warehouse/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncStorageWarehouse viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/storage-self/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncStorageSelf viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/press-page/"
          render={() => {
            removeViewPort(); // remove meta viewport that defined in index.html
            return (
              <Suspense fallback={<LoadingPage />}>
                <AsyncPressPage viewModel={viewModel} />
              </Suspense>
            );
          }}
        />
        <Route
          exact
          path="/what-is-flatrate/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncWhatIsFlatrateNetwork viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/what-is-included/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncWhatIsIncluded viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/how-it-works/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncHowItWorks viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/testimonials/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncTestimonials viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/customer-service/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncCustomerService viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/faq/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncFaqPage viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/agreement/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncAgreement viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/privacy/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncPrivacy viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/sitemap/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncSitemap viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/moving-insurance/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncInsurance viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/benefits/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncBenefits viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/partnership/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncPartnership viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/service-providers/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncForServiceProviders viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/movers-faq/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncMoversFaq viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/corporate-relocation/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncCorporateRelocation viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/corporate-relocation-about/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncCorporateRelocationAbout viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/corporate-relocation-registration/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncCorporateRelocationRegistration viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/corporate-relocation-faq/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncCorporateRelocationFaq viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/deals/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncDeals viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/start-new/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncDnaOnly viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/scholarship/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncScholarship viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/careers/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncCareers viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/furniture-moving/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncFurnitureMoving viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/house-movers/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncHouseMovers viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/professional-movers/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncProfessionalMovers viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/membership-agreement/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncMembershipAgreement viewModel={viewModel} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/best-moving-companies-best-prices/"
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncFlatrateNetworkPromise viewModel={viewModel} />
            </Suspense>
          )}
        />

        {/* only vendors sign in and sign up will be redirected to api.flatrate subdomain, all other invalid URLs go to AsyncPageNotFound */}
        <Route
          exact
          path="/vendors/sign_in"
          render={({ location }) => (window.location.href = process.env.REACT_APP_BASE_API_URL + location.pathname)}
        />
        <Route
          exact
          path="/vendors/sign_up"
          render={({ location }) => (window.location.href = process.env.REACT_APP_BASE_API_URL + location.pathname)}
        />
        <Route
          exact
          path="/users/edit"
          render={({ location }) => (window.location.href = process.env.REACT_APP_BASE_API_URL + location.pathname)}
        />
        <Route
          exact
          path="/admin"
          render={({ location }) => (window.location.href = process.env.REACT_APP_BASE_API_URL + location.pathname)}
        />
        <Route
          path="/feedback/:id/edit"
          render={({ location }) => (window.location.href = process.env.REACT_APP_BASE_API_URL + location.pathname)}
        />
        <Route
          render={() => (
            <Suspense fallback={<LoadingPage />}>
              <AsyncPageNotFound />
            </Suspense>
          )}
        />
      </Switch>

      {/*language=CSS*/}
      <style jsx>{`
        .app-container {
          font-family: ProximaNovaRegular, sans-serif;
          position: relative;
          height: 100%;
          background-color: #fff;
        }
        @media (max-width: 992px) {
          .app-container {
            padding-top: 70px;
          }
        }
        @media (min-width: 993px) {
          .app-container {
            padding-top: 55px;
          }
        }
      `}</style>
    </div>
  );
};

export default connect(mapStateToProps, { checkCookies })(FullWidthLayout);
