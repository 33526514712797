import { handleActions } from 'redux-actions';
import { FLOW_STEPS } from '../../constants';
import { SET_CURRENT_STEP, SET_LAST_STEP, SET_STEP_COMPLETED, RESET_FUNNEL, HOURLY_RESET_FUNNEL,  SET_AUTH_REDIRECT } from '../actionTypes';

const initialState = {
  currentStep: '',
  lastStep: '',
  bodyClass: 'location-details-body',
  progressBarSteps:{
    INVENTORY: {
      id:  'inventory',
      title:  'Inventory',
      step: 'INVENTORY',
      nextStepButtonText: 'continue',
      showNextStepButton: true,
      isCompleted: true,
      nextStep: 'DETAILS'
    },
    DETAILS: {
      id:  'details',
      title:  'Details',
      step: 'DETAILS',
      nextStepButtonText: 'continue',
      showNextStepButton: true,
      isCompleted: false,
      nextStep: 'COMPARE'
    },
    COMPARE: {
      id:  'compare',
      title:  'Compare Movers',
      step: 'COMPARE',
      nextStepButtonText: 'continue',
      showNextStepButton: false,
      isCompleted: false,
      nextStep: 'BOOK'
    },
    PLAN: {
      id:  'plan',
      title:  'Review',
      step: 'PLAN',
      nextStepButtonText: 'continue',
      showNextStepButton: false,
      isCompleted: false,
      nextStep: 'BOOK'
    },
    BOOK: {
      id:  'book',
      title:  'Book Online',
      step: 'BOOK',
      nextStepButtonText: 'continue',
      showNextStepButton: true,
      isCompleted: false,
      nextStep: 'CONGRATS'
    }
  }
};

const hourlyIntialState = {
  currentStep: 'compare',
  lastStep: 'compare',
  bodyClass: 'location-details-body',
  progressBarSteps:{
    COMPARE: {
      id:  'compare',
      title:  'Compare Movers',
      step: 'COMPARE',
      nextStepButtonText: 'continue',
      showNextStepButton: false,
      isCompleted: false,
      nextStep: 'DETAILS'
    },
    DETAILS: {
      id:  'details',
      title:  'Details',
      step: 'DETAILS',
      nextStepButtonText: 'continue',
      showNextStepButton: true,
      isCompleted: false,
      nextStep: 'BOOK'
    },
    PLAN: {
      id:  'plan',
      title:  'Review',
      step: 'PLAN',
      nextStepButtonText: 'continue',
      showNextStepButton: false,
      isCompleted: false,
      nextStep: 'BOOK'
    },
    BOOK: {
      id:  'book',
      title:  'Book Online',
      step: 'BOOK',
      nextStepButtonText: 'continue',
      showNextStepButton: true,
      isCompleted: false,
      nextStep: 'CONGRATS'
    }
  }
};

const funnelReducer = handleActions({
  [SET_CURRENT_STEP]: (state, { payload }) => ({
    ...state,
    currentStep: payload
  }),
  [SET_LAST_STEP]: (state, { payload }) => ({
    ...state,
    lastStep: payload
  }),
  [SET_STEP_COMPLETED]: (state, {payload}) => {
    if( (payload !== 'INVENTORY') ||
        (payload !== 'DETAILS') ||
        (payload !== 'PLAN') ||
        (payload !== 'BOOK') ||
        (payload !== 'CONGRATS')) {
      return ({
        ...state,
        progressBarSteps: {
          ...state.progressBarSteps,
          [payload]: {
            ...state.progressBarSteps[payload],
            isCompleted: true
          }
        }
      });
    }
  },
  [RESET_FUNNEL]: () => ({
    ...initialState
  }),
  [HOURLY_RESET_FUNNEL]: () => ({
    ...hourlyIntialState
  }),
  [SET_AUTH_REDIRECT]: (state, {payload}) => ({
    ...state,
    authRedirect: payload
  }),
}, initialState);

export default funnelReducer;


export const allowedToVisit = (state, action) => {
  if(state.auth.isAdmin || state.movePlan.currentPlan.is_read_only ) {
    return true;
  }
  if(state.movePlan.currentPlan.is_read_only) { return true }
  if(state.funnel.currentStep === 'PLAN' && action === 'print') {
    return true;
  }
  if(state.movePlan.currentPlan.is_done && state.funnel.action === 'PLAN') {
    return false;
  }
  if(state.auth.isMover) {
    if(action === 'PLAN') {
      return true
    } else if(action === 'BOOK') {
      return false;
    } else return !state.movePlan.currentPlan.is_public;
  }

  if(!!state.movePlan.currentPlan.last_step) {
    return !!(FLOW_STEPS.indexOf(action) <= FLOW_STEPS.indexOf(state.movePlan.currentPlan.last_step.toUpperCase()));
  }
  return false
};

export const toUSD = (amount) => (
  !!amount ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount) : '$0.00'
);
