import {
  ADD_NOTE,
  APPLY_COUPON,
  DELETE_ADJUSTMENT,
  DELETE_NOTE,
  FINALIZE_PLAN,
  GET_HISTORY,
  GET_NOTES,
  INIT_PLAN,
  INIT_PLAN_FINISHED,
  INIT_PRINT_PLAN,
  INIT_ROUTES,
  NETWORK_SUCCESS,
  TOGGLE_STORAGE_PLAN,
  UPDATE_ADJUSTMENT,
  GET_ADJUSTMENT_CALC,
  NETWORK_FAILURE,
  SET_SELECTED_TAB_PLAN,
  MOVE_DAY_ADJUSTMENT
} from '../actionTypes';
import { getCurrentMoveplan, setCurrentMoveplanID, updateMovePlan } from '../actions/movePlan';
import { push } from 'connected-react-router';
import { gotoBook, gotoCompare, setCurrentStep } from '../actions/funnel';
import { fetchInventory } from '../actions/inventory';
import {
  getNotes,
  setAdjustmentLoading,
  setAdminNotes,
  setHistory,
  setHistoryUpdating,
  setMoverNotes,
  setNotesUpdating,
  setRoutes,
  setShowStorage,
  setPlanUpdating,
  updateCoupon,
  setSelectedTabPlan,
  initRoutes,
  setAdjustmentPayload,
  setMdaSending,
  setMdaStatus
} from '../actions/plan';
import { getPricingBreakdown } from '../actions/compare';
import { apiRequest } from '../actions/api';
import has from 'lodash-es/has';
import { addDays } from 'date-fns';
import { openModal, closeModal } from '../actions/modals';

const planMiddleware = ({ getState, dispatch }) => next => action => {
  next(action);

  if (action.type === INIT_PLAN) {
    dispatch(setPlanUpdating(true));
    dispatch(setCurrentStep('PLAN'));
    if (getState().movePlan.currentMPID !== action.payload.movePlanId) {
      dispatch(setCurrentMoveplanID(action.payload.movePlanId));
    }
    dispatch(getCurrentMoveplan());
    dispatch(fetchInventory(action.payload.movePlanId));
  }

  if (action.type === INIT_PRINT_PLAN) {
    dispatch(initRoutes());
    if (has(getState().movePlan.currentPlan, 'mover')) {
      dispatch(getPricingBreakdown(getState().movePlan.currentPlan.mover.id));
    }
  }

  if (action.type === INIT_PLAN_FINISHED) {
    if (has(getState().movePlan.currentPlan, 'details')) {
      dispatch(initRoutes());
      if (!!getState().movePlan.currentPlan.details.storage_in_transit) {
        dispatch(setShowStorage(true));
      }
    }
    dispatch(getPricingBreakdown(getState().movePlan.currentPlan.mover.id));
    dispatch(setSelectedTabPlan('myMoveplan'));
    dispatch(setPlanUpdating(false));
  }

  if (action.type === FINALIZE_PLAN) {
    dispatch(openModal({ name: 'loader', properties: { state: 'initial' } }));
    if (getState().movePlan.currentPlan.is_booked) {
      dispatch(push(`/moving/${getState().movePlan.currentMPID}/billing`));
    } else if (!!!getState().movePlan.currentPlan.mover) {
      dispatch(gotoCompare());
    } else {
      dispatch(gotoBook());
    }
  }

  if (action.type === INIT_ROUTES) {
    // TODO: extract this to environment variables
    let routesPrefix = 'https://maps.googleapis.com/maps/api/staticmap?scale=2&size=400x200&maptype=roadmap&zoom=16';
    let mapStyles = '&style=feature:landscape|element:geometry.fill|weight:1';
    let labelStyles = 'style=feature:all|element:labels.text.fill|visibility:on';
    let iconStyle = `markers=icon:${process.env.REACT_APP_CDN_IMAGES}/plan/map-marker.png`;
    let mapKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;
    let { details } = getState().movePlan.currentPlan;

    let routes = {
      pick_up: {
        enabled: true,
        cssClass: 'from',
        displayName: 'Moving From',
        loading: false,
        bodyEnabled: true,
        altBodyText: '',
        mapUrl: `${routesPrefix}&${mapStyles}&${labelStyles}&${iconStyle}|${getLatLngStr(
          details['pick_up'].latitude,
          details['pick_up'].longitude
        )}&key=${mapKey}`
      },
      drop_off: {
        enabled: true,
        cssClass: 'to',
        displayName: 'Moving To',
        loading: false,
        bodyEnabled: !details.move_into_storage,
        altBodyText: 'Storage',
        mapUrl: !details.move_into_storage
          ? `${routesPrefix}&${mapStyles}&${labelStyles}&${iconStyle}|${getLatLngStr(
              details['drop_off'].latitude,
              details['drop_off'].longitude
            )}&key=${mapKey}`
          : ''
      }
    };

    if (has(details, 'extra_pick_up')) {
      routes.extra_pick_up = {
        enabled: getState().movePlan.currentPlan.details.extra_pick_up_enabled,
        cssClass: 'extra-from',
        displayName: 'Extra pickup',
        loading: false,
        bodyEnabled: getState().movePlan.currentPlan.details.extra_pick_up_enabled,
        altBodyText: '',
        mapUrl: `${routesPrefix}&${mapStyles}&${labelStyles}&${iconStyle}|${getLatLngStr(
          details['extra_pick_up'].latitude,
          details['extra_pick_up'].longitude
        )}&key=${mapKey}`
      };
    }
    if (has(details, 'extra_drop_off')) {
      routes.extra_drop_off = {
        enabled: getState().movePlan.currentPlan.details.extra_drop_off_enabled,
        cssClass: 'extra-to',
        displayName: 'Extra drop off',
        loading: false,
        bodyEnabled: getState().movePlan.currentPlan.details.extra_drop_off_enabled,
        altBodyText: '',
        mapUrl: `${routesPrefix}&${mapStyles}&${labelStyles}&${iconStyle}|${getLatLngStr(
          details['extra_drop_off'].latitude,
          details['extra_drop_off'].longitude
        )}&key=${mapKey}`
      };
    }
    dispatch(setRoutes(routes));
  }

  if (action.type === TOGGLE_STORAGE_PLAN) {
    let hasStorage = getState().movePlan.currentPlan.details.storage_in_transit;
    dispatch(
      updateMovePlan({
        move_plan: {
          storage_in_transit: !hasStorage,
          storage_move_out_date: !hasStorage
            ? addDays(new Date(getState().movePlan.currentPlan.details.move_date), 1).format('MM/DD/YYYY')
            : null
        }
      })
    );
  }

  if (action.type === GET_HISTORY) {
    dispatch(setHistoryUpdating(true));
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/history.json`,
        method: 'GET',
        fromAction: GET_HISTORY
      })
    );
  }

  if (action.type === GET_NOTES) {
    dispatch(setNotesUpdating(true));
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/notes`,
        method: 'GET',
        params: {
          type: getState().plan.selectedTab === 'adminNotes' ? 'admin' : 'mover'
        },
        fromAction: GET_NOTES
      })
    );
  }

  if (action.type === ADD_NOTE) {
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/notes`,
        method: 'POST',
        params: {
          note: {
            content: action.payload,
            updated_at: Date.now(),
            admin_generated: getState().plan.selectedTab === 'adminNotes' && getState().auth.isAdmin
          }
        },
        fromAction: ADD_NOTE
      })
    );
  }

  if (action.type === DELETE_NOTE) {
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/notes/${action.payload}`,
        method: 'DELETE',
        fromAction: DELETE_NOTE
      })
    );
  }

  if (action.type === UPDATE_ADJUSTMENT) {
    dispatch(setAdjustmentLoading(true));
    let payload = {};
    if (has(action.payload, 'description')) {
      payload = action.payload;
    } else {
      const statePayload = getState().plan.adjustmentPayload;
      payload = {
        amount_in_dollars: statePayload.amount_in_cents / 100,
        applies_to: statePayload.applies_to,
        description: statePayload.description,
        is_applied_before_discounts: true,
        is_percentage: false,
        percentage: null,
        move_plan_id: getState().movePlan.currentMPID
      };
    }
    dispatch(
      apiRequest({
        url: `api/v3/admin/admin_adjustments`,
        method: 'POST',
        params: {
          admin_adjustment: payload,
          move_plan_id: getState().movePlan.currentMPID
        },
        fromAction: UPDATE_ADJUSTMENT
      })
    );
  }

  if (action.type === DELETE_ADJUSTMENT) {
    dispatch(setAdjustmentLoading(true));
    dispatch(
      apiRequest({
        url: `api/v3/admin/admin_adjustments/${action.payload}`,
        method: 'DELETE',
        fromAction: DELETE_ADJUSTMENT
      })
    );
  }

  if (action.type === APPLY_COUPON) {
    dispatch(updateCoupon({ key: 'loading', value: true }));
    dispatch(updateCoupon({ key: 'couponApplied', value: false }));
    dispatch(updateCoupon({ key: 'couponInvalid', value: false }));
    dispatch(
      apiRequest({
        url: `api/v3/jobs/${getState().movePlan.currentPlan.job.id}/apply_coupon`,
        method: 'PATCH',
        params: {
          coupon_code: getState().plan.coupon.promoCode
        },
        fromAction: APPLY_COUPON
      })
    );
  }

  if (action.type === GET_ADJUSTMENT_CALC) {
    dispatch(setAdjustmentLoading(true));
    const payload = {
      name: action.payload.adjustment_option,
      move_plan_id: getState().movePlan.currentMPID,
      input_value: !!action.payload.amount ? action.payload.amount : undefined
    };
    dispatch(
      apiRequest({
        url: `api/v3/admin/admin_adjustments/execute_template`,
        method: 'POST',
        params: payload,
        fromAction: GET_ADJUSTMENT_CALC
      })
    );
  }

  if (action.type === SET_SELECTED_TAB_PLAN) {
    // This recalculates prices and gets mp after admin changes or promos are applied
    if (action.payload === 'myMoveplan') {
      if (has(getState().movePlan.currentPlan, 'mover')) {
        dispatch(getPricingBreakdown(getState().movePlan.currentPlan.mover.id));
      }
      if (getState().plan.prevTab !== 'myMoveplan') {
        dispatch(getCurrentMoveplan());
      }
    }
  }

  if (action.type === MOVE_DAY_ADJUSTMENT) {
    dispatch(setMdaSending(true));
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/mda_links`,
        method: 'POST',
        params: action.payload,
        fromAction: MOVE_DAY_ADJUSTMENT
      })
    );
  }

  if (action.type === NETWORK_SUCCESS) {
    switch (action.meta.originalRequest.fromAction) {
      case GET_HISTORY:
        dispatch(setHistory(action.payload.response.data.history));
        dispatch(setHistoryUpdating(false));
        break;
      case ADD_NOTE:
        dispatch(getNotes(getState().auth.isAdmin && getState().plan.selectedTab === 'adminNotes' ? 'admin' : 'mover'));
        break;
      case DELETE_NOTE:
        dispatch(getNotes());
        break;
      case GET_NOTES:
        const notes = has(action.payload.response.data, 'notes') ? action.payload.response.data.notes : [];
        getState().plan.selectedTab === 'adminNotes' && getState().auth.isAdmin
          ? dispatch(setAdminNotes(notes))
          : dispatch(setMoverNotes(notes));
        dispatch(setNotesUpdating(false));
        break;
      case UPDATE_ADJUSTMENT:
        dispatch(getCurrentMoveplan());
        dispatch(setAdjustmentPayload({}));
        if (getState().modals.length > 0) dispatch(closeModal());
        dispatch(setAdjustmentLoading(false));
        break;
      case APPLY_COUPON:
        dispatch(updateCoupon({ key: 'couponApplied', value: true }));
        dispatch(updateCoupon({ key: 'couponInvalid', value: false }));
        dispatch(updateCoupon({ key: 'loading', value: false }));
        break;
      case GET_ADJUSTMENT_CALC:
        dispatch(setAdjustmentPayload(action.payload.response.data));
        dispatch(getCurrentMoveplan());
        dispatch(setAdjustmentLoading(false));
        break;
      case DELETE_ADJUSTMENT:
        dispatch(getCurrentMoveplan());
        dispatch(setAdjustmentLoading(false));
        break;
      case MOVE_DAY_ADJUSTMENT:
        dispatch(setMdaSending(false));
        if (has(action.payload.response.data, 'link')) {
          window.open(action.payload.response.data.link, '_blank');
        }
        if (action.meta.originalRequest.params.type === 'email') {
          dispatch(setMdaStatus({ email: true, sms: false }));
        }
        if (action.meta.originalRequest.params.type === 'sms') {
          dispatch(setMdaStatus({ email: false, sms: true }));
        }
        break;
      default:
        break;
    }
  }

  if (action.type === NETWORK_FAILURE) {
    switch (action.meta.originalRequest.fromAction) {
      case APPLY_COUPON:
        dispatch(updateCoupon({ key: 'couponApplied', value: false }));
        dispatch(updateCoupon({ key: 'couponInvalid', value: true }));
        dispatch(updateCoupon({ key: 'loading', value: false }));
        break;
      case MOVE_DAY_ADJUSTMENT:
        dispatch(setMdaSending(false));
        dispatch(
          openModal({
            name: 'apiError',
            properties: { error: 'Something went wrong trying to update move day adjustments.' }
          })
        );
        break;
      default:
        break;
    }
  }
};

const getLatLngStr = (lat, lng) => {
  if (!!!lat || !!!lng) {
    return '';
  } else {
    return `${lat}, ${lng}`;
  }
};

export default planMiddleware;
