import {handleActions} from 'redux-actions';
import { SET_MARKETING_LOADING, SET_CITY_DATA, SET_STATE_DATA } from '../actionTypes';


const initialState = {
  loading: true,
  cityData: false,
  stateData: false
};

const marketingReducer = handleActions({
    [SET_MARKETING_LOADING]: (state, {payload}) => ({
      ...state,
      loading: payload
    }),
    [SET_CITY_DATA]: (state, {payload}) => ({
      ...state,
      cityData: payload
    }),
    [SET_STATE_DATA]: (state, {payload}) => ({
      ...state,
      stateData: payload
    })
  },

  initialState);

export default marketingReducer;