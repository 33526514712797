import { UPLOAD_FILE, UPLOAD_COI, CONFIRM_S3_UPLOAD, FETCH_EXAMPLE_UPLOAD, FETCH_COMPLETED_UPLOAD, SET_S3_UPLOAD_URL, DELETE_UPLOAD } from '../actionTypes';

export const uploadFile = () => ({
  type: UPLOAD_FILE
});

export const uploadCoi = info => ({
  type: UPLOAD_COI,
  payload: info
});

export const confirmUpload = filename => ({
  type: CONFIRM_S3_UPLOAD,
  payload: filename
});

export const fetchExampleUpload = addressKey => ({
  type: FETCH_EXAMPLE_UPLOAD,
  payload: addressKey
});

export const fetchCompletedUpload = addressKey => ({
  type: FETCH_COMPLETED_UPLOAD,
  payload: addressKey
});

export const setS3UploadURL = (url, addressKey, completed) => ({
  type: SET_S3_UPLOAD_URL,
  payload: { url, addressKey, completed }
});

export const deleteUpload = filename => ({
  type: DELETE_UPLOAD,
  payload: filename
});