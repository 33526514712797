import { handleActions } from 'redux-actions';
import {
  SET_BOOK_CONTACT,
  SET_CARD_ERRORS,
  SET_CLIENT_TOKEN,
  SET_HOSTED_FIELDS_INSTANCE,
  TOGGLE_IS_SUBMITTING, SET_SPLITIT_URL, SET_BOOK_NONCE, SET_SPLITIT_STATUS, SET_PROMO_LOADING, SET_SPLITIT_NUMBER, SET_PAYPAL_COMPLETE, SET_PAYPAL_EMAIL, CANCEL_PAYPAL, CANCEL_SPLITIT, SET_BOOK_LOADING
} from '../actionTypes';
import has from 'lodash-es/has';

const initialState = {
  errorMessages: undefined,
  clientToken: '',
  cardErrors: {
    number: null,
    cvv: null,
    expirationMonth: null,
    expirationYear: null,
    postalCode: null
  },
  hostedFieldsInstance: {},
  bookPayload: {},
  isSubmitting: false,
  promoLoading: false,
  splititStatus: false,
  loading: false,
};

const bookReducer = handleActions(
  {
    [SET_CLIENT_TOKEN]: (state, {payload}) => ({
      ...state,
      clientToken: payload
    }),
    [SET_CARD_ERRORS]: (state, {payload}) => ({
      ...state,
      cardErrors: {
        ...state.cardErrors,
        ...payload
      }
    }),
    [SET_HOSTED_FIELDS_INSTANCE]: (state, {payload}) => ({
      ...state,
      hostedFieldsInstance: payload
    }),
    [SET_BOOK_CONTACT]: (state, {payload}) => ({
      ...state,
      bookPayload: {
        ...state.bookPayload,
        contact_information: payload.contact_information
      }
    }),
    [SET_BOOK_NONCE]: (state, {payload}) => ({
      ...state,
      bookPayload: {
        ...state.bookPayload,
        payment: {
          ...state.bookPayload.payment,
          billing_nonce: payload
        }
      }
    }),
    [TOGGLE_IS_SUBMITTING]: (state) => ({
      ...state,
      isSubmitting: !state.isSubmitting
    }),
    [SET_SPLITIT_URL]: (state, {payload}) => ({
      ...state,
      splititUrl: payload
    }),
    [SET_SPLITIT_STATUS]: (state, {payload}) => ({
      ...state,
      splititStatus: payload
    }),
    [SET_PROMO_LOADING]: (state, {payload}) => ({
      ...state,
      promoLoading: payload
    }),
    [SET_SPLITIT_NUMBER]: (state, {payload}) => ({
      ...state,
      bookPayload: {
        ...state.bookPayload,
        payment: {
          ...state.bookPayload.payment,
          InstallmentPlanNumber: payload
        }
      }
    }),
    [SET_PAYPAL_COMPLETE]: (state, {payload}) => ({
      ...state,
      paypalComplete: payload
    }),
    [SET_PAYPAL_EMAIL]: (state, {payload}) => ({
      ...state,
      paypalEmail: payload
    }),
    [CANCEL_PAYPAL]: (state) => ({
      ...state,
      paypalEmail: '',
      paypalComplete: false,
      bookPayload: {
        ...state.bookPayload,
        payment: {}
      }
    }),
    [CANCEL_SPLITIT]: (state) => ({
      ...state,
      splititUrl: false,
      bookPayload: {
        ...state.bookPayload,
        payment: {}
      }
    }),
    [SET_BOOK_LOADING]: (state, {payload}) => ({
      ...state,
      loading: payload
    })
}, initialState);


export const showPromoNotification = (movePlan, analytics) => {
  // todo: add localstorage check here
  return has(analytics.couponData, 'code') && !movePlan.currentPlan.is_booked && !movePlan.currentPlan.is_done;
};

export const getTotalCost = (movePlan, analytics) => {
  if(movePlan.currentPlan.is_booked) {
    return movePlan.currentPlan.pricing.total_cost
  }
  if(has(analytics.couponData, 'coupon')) {
    if(analytics.couponData.coupon.percentage) {
      return Number.parseFloat(movePlan.currentPlan.pricing.total_cost - (movePlan.currentPlan.pricing.total_cost * (analytics.couponData.coupon.discount_percentage/100))).toFixed(2)
    } else {
      return Number.parseFloat(movePlan.currentPlan.pricing.total_cost - analytics.couponData.coupon.discount_cents / 100).toFixed(2)
    }
  } else return movePlan.currentPlan.pricing.total_cost
}



export default bookReducer;