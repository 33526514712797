import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { openModal } from '../../redux/actions/modals';
import { track } from '../../redux/actions/analytics';
import { setDnaError } from '../../redux/actions/movePlan';


const mapStateToProps = ({ movePlan }) => ({
  dnaError: movePlan?.dnaError ? movePlan.dnaError : false
});

const CoverMobileNewDesign = ({ viewModel, partner, setDnaError }) => {

  useEffect(() => setDnaError(false), [setDnaError]);

  return (
    <>
      <section className={`text-center home-cover-small`}>
        <div className={`${'container cover-box mobile-greeting landscape'}`}>
          <h1 className='landing__title'>
            Book <span className="flatrate-network-red">your move</span> online in minutes!
          </h1>
          <PartnerCoverText viewModel={viewModel} partner={partner} />
          <p className='landing__subtitle'>Compare guaranteed moving quotes from the best moving companies</p>
          <p className='landing__subtitle'>Book online & save 45% on your moving costs</p>
        </div>

        <button className='get-quotes__button'>
          <a href={`${process.env.REACT_APP_NEW_DESIGN_URL}/distance`} style={{ color: 'white' }}>Get Quotes</a> 
        </button>

      </section>

      <style jsx>{`
        h1 {
          font-size: 3.5rem;
          line-height: 3.5rem;
          font-weight: 800;
          font-family: ProximaNovaBold, sans-serif;
          text-align: center;
          padding: 1rem 0 0 0;
          text-shadow: 0 13px 27px rgba(50, 50, 93, 0.25);
        }
        p {
          font-size: 1.1rem;
          margin: 0;
          line-height: 1.25;
        }

        .background-pattern {
          background-color: #5cbed9;
        }

        .center-container {
          border-radius: 4px;
          background: #A8B3BE;
          box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3),
            0 -6px 16px -6px rgba(0, 0, 0, 0.025);
        }
        .dna-column {
          z-index: 1;
        }
        .dna-holder {
          background: #f0fcff;
          border-radius: 0 0 3px 3px;
        }
        .dna-holder p {
          margin: 0;
        }

        .primary-btn-large {
          height: 60px;
        }
        .switch-view button {
          background: none;
          border: none;
          border-bottom: 1px solid transparent;
        }
        .switch-view button:hover {
          border-bottom: 1px solid white;
        }

        ///////////// Mobile ///////////////
        @media (max-width: 330px) { 
          .landing__title {
            font-size: 4.7vh !important;
            line-height: 5vh !important;
          }
          .landing__subtitle {
            font-size: 2.5vh !important;
          }
        }

        .home-cover-small {
          background: rgb(27, 55, 73);
          background: linear-gradient(-187deg, rgb(27, 55, 73) 65%, rgb(39, 200, 234) 35%);
          height: 54vh;
        }
        .home-cover-small h1 {
          font-size: 2.4rem;
          line-height: 2.5rem;
          font-weight: 700;
          font-family: 'ProximaNovaBold', sans-serif;
          color: #fff !important;
        }
        .home-cover-small p {
          color: #fff;
          font-size: 1rem;
          line-height: 1.1;
          margin-bottom: 7px;
        }
        .mobile-greeting {
          padding: 1.5rem 1rem;
        }
        .get-quotes__button {
          margin-top: 6vh;
          width: 60%;
          color: #fff;
          background: #e23337;
          border: none;
          height: 6vh;
          border-radius: 8px;
          font-family: ProximaNovaBold,sans-serif;
          font-weight: 700;
          letter-spacing: .1em;
          box-shadow: 0 8px 6px rgb(0 0 0 / 30%);
        }
      `}</style>
    </>
  );
};

const PartnerCoverText = ({ viewModel, partner }) => {
  // PARTNER BRANDED GREETINGS
  let params = new URLSearchParams(window.location.search);
  let greeting = params.get('promo');

  if (greeting === 'NFCAMENITY5' || partner === 'nfcamenity') {
    return (
      <>
        <div className={`marketing-page mb-3 text-white d-flex justify-content-center`}>
          <div className="text-greeting position-relative text-left d-flex align-items-center">
            <h5>Welcome, NFC Amenity Management customers!</h5>
          </div>
        </div>
        <style jsx>{`
          .text-greeting {
            padding-left: 60px;
            min-height: 60px;
          }
          .text-greeting::before {
            content: '';
            display: block;
            width: 50px;
            height: 50px;
            position: absolute;
            top: 0;
            left: 0;
            background: url(${process.env.REACT_APP_CDN_IMAGES}/partners/nfc_logo.png) no-repeat top center / contain;
          }
        `}</style>
      </>
    );
  } else if (greeting === 'EQUIEM10' || partner === 'equiem') {
    return (
      <>
        <>
          <div className={`marketing-page mb-3 text-white d-flex justify-content-center`}>
            <div className="text-greeting position-relative text-left d-flex align-items-center">
              <h5>Welcome, Equiem clients!</h5>
            </div>
          </div>
          <style jsx>{`
            .text-greeting {
              padding-left: 110px;
              min-height: 60px;
            }
            .text-greeting::before {
              content: '';
              display: block;
              width: 100px;
              height: 50px;
              position: absolute;
              top: 0;
              left: 0;
              background: url(${process.env.REACT_APP_CDN_IMAGES}/partners/equiem_logo.png) no-repeat center center /
                contain;
            }
          `}</style>
        </>
      </>
    );
  } else if (greeting === 'utilityconnect' || partner === 'utilityconnect') {
    return (
      <>
        <>
          <div className={`marketing-page mb-3 text-white d-flex justify-content-center`}>
            <div className="text-greeting position-relative text-left d-flex align-items-center">
              <h5>Welcome, Utility Connect clients!</h5>
            </div>
          </div>
          <style jsx>{`
            .text-greeting {
              padding-left: 220px;
              min-height: 60px;
              color: #183244
            }
            .text-greeting::before {
              content: '';
              display: block;
              width: 200px;
              height: 50px;
              position: absolute;
              top: 0;
              left: 0;
              background: url(${process.env.REACT_APP_CDN_IMAGES}/partners/utility_connect.png) no-repeat center center /
                contain;
            }
          `}</style>
        </>
      </>
    );
  }
  else {
    return <></>;
  }
};

export default connect(
  mapStateToProps,
  { openModal, track, setDnaError }
)(CoverMobileNewDesign);
