import { handleActions } from 'redux-actions';
import {
  SET_CURRENT_MOVEPLAN_ID,
  SET_CURRENT_PLAN_INFO,
  ADD_BOX_PURCHASE,
  SET_MOVE_DATE,
  SET_VALID_DATES,
  REMOVE_BOX_PURCHASE,
  SET_BOX_PURCHASES,
  SET_COI_INFO,
  SET_TIME_WINDOW,
  SET_RESCHEDULE_REQUEST,
  SET_MOVEPLAN_INSURANCE,
  INIT_BOX_PURCHASES,
  CLEAR_BOX_PURCHASES,
  SET_MOVEPLAN_LOADING,
  SET_DNA_ERROR,
  UPDATE_BOX_PURCHASE_COUNT,
  CANCEL_CANCEL,
  SET_HOURLY_RENDER_LOADING,
  REMOVE_HOURLY_FLAG,
  SET_CURRENT_FR_MOVEPLAN_ID
} from '../actionTypes';
import sumBy from 'lodash-es/sumBy';
import { totalBoxes } from './inventory';
import has from 'lodash-es/has';
import { INVENTORY_BOXES } from '../../constants';
import { isAfter, isBefore, addDays, addYears, isWeekend, subDays, getHours, isSameDay } from 'date-fns';

const initialState = {
  moverID: '',
  currentMPID: '',
  fr_move_plan_id: '',
  currentPlan: {
    services: {},
    box_purchases: [
      {
        box_type_id: 1,
        quantity: 0
      },
      {
        box_type_id: 2,
        quantity: 0
      },
      {
        box_type_id: 3,
        quantity: 0
      },
      {
        box_type_id: 4,
        quantity: 0
      },
      {
        box_type_id: 5,
        quantity: 0
      },
      {
        box_type_id: 6,
        quantity: 0
      },
      {
        box_type_id: 7,
        quantity: 0
      }
    ],
    billing: {
      coupon: {
        code: ''
      }
    }
  },
  validDates: {},
  rescheduleData: {},
  loading: false,
  hourlyRenderLoading: false,
  ownerInfo: {}
};

const movePlanReducer = handleActions(
  {
    [SET_CURRENT_MOVEPLAN_ID]: (state, { payload }) => ({
      ...state,
      currentMPID: payload.movePlanId,
      shortMPID: payload.movePlanId.slice(0, 6).toUpperCase()
    }),
    [SET_CURRENT_FR_MOVEPLAN_ID]: (state, { payload }) => ({
      ...state,
      fr_move_plan_id: payload.fr_move_plan_id
    }),
    [SET_CURRENT_PLAN_INFO]: (state, { payload }) => ({
      ...state,
      currentPlan: payload.plan
    }),
    [SET_BOX_PURCHASES]: (state, { payload }) => ({
      ...state,
      currentPlan: {
        ...state.currentPlan,
        box_purchases: payload.box_purchases
      }
    }),
    [ADD_BOX_PURCHASE]: (state, action) => {
      let temp = state.currentPlan.box_purchases;
      temp[action.payload - 1].quantity += 1;
      return {
        ...state,
        currentPlan: {
          ...state.currentPlan,
          box_purchases: temp
        }
      };
    },
    [REMOVE_BOX_PURCHASE]: (state, action) => {
      if (state.currentPlan.box_purchases[action.payload - 1].quantity === 0) {
        return state;
      } else {
        let temp = state.currentPlan.box_purchases;
        temp[action.payload - 1].quantity -= 1;
        return {
          ...state,
          currentPlan: {
            ...state.currentPlan,
            box_purchases: temp
          }
        };
      }
    },
    [UPDATE_BOX_PURCHASE_COUNT]: (state, { payload }) => {
      let temp = state.currentPlan.box_purchases;
      temp[payload.boxId - 1].quantity = payload.count;
      return {
        ...state,
        currentPlan: {
          ...state.currentPlan,
          box_purchases: temp
        }
      };
    },
    [SET_MOVE_DATE]: (state, action) => {
      return {
        ...state,
        currentPlan: {
          ...state.currentPlan,
          details: {
            ...state.currentPlan.details,
            move_date: action.payload
          }
        }
      };
    },
    [SET_VALID_DATES]: (state, action) => {
      return {
        ...state,
        validDates: action.payload
      };
    },
    [SET_COI_INFO]: (state, action) => {
      return {
        ...state,
        currentPlan: {
          ...state.currentPlan,
          details: {
            ...state.currentPlan.details,
            [action.payload.addressKey]: {
              ...state.currentPlan.details[action.payload.addressKey],
              certificate_of_insurance_required: action.payload.certificate_of_insurance_required,
              management_contact_name: action.payload.management_contact_name,
              management_phone_number: action.payload.management_phone_number
            }
          }
        }
      };
    },
    [SET_TIME_WINDOW]: (state, action) => {
      return {
        ...state,
        currentPlan: {
          ...state.currentPlan,
          details: {
            ...state.currentPlan.details,
            [action.payload.addressKey]: {
              ...state.currentPlan.details[action.payload.addressKey],
              elevator_start_time: action.payload.elevator_start_time,
              elevator_end_time: action.payload.elevator_end_time
            }
          }
        }
      };
    },
    [SET_RESCHEDULE_REQUEST]: (state, { payload }) => ({
      ...state,
      rescheduleData: {
        ...state.rescheduleData,
        ...payload
      }
    }),
    [SET_MOVEPLAN_INSURANCE]: (state, { payload }) => ({
      ...state,
      currentPlan: {
        ...state.currentPlan,
        insurance_requested: payload
      }
    }),
    [INIT_BOX_PURCHASES]: (state, { payload }) => {
      let initBoxPurchases = [];
      INVENTORY_BOXES.map(
        (box, index) =>
          (initBoxPurchases[index] = {
            box_type_id: box.id,
            quantity: !!payload[box.id] ? payload[box.id].quantity : 0,
            name: !!payload[box.id] ? payload[box.id].name : '',
            is_user_selected: !!payload[box.id] ? payload[box.id.is_user_selected] : false
          })
      );
      return {
        ...state,
        currentPlan: {
          ...state.currentPlan,
          box_purchases: initBoxPurchases
        }
      };
    },
    [CLEAR_BOX_PURCHASES]: state => {
      return {
        ...state,
        currentPlan: {
          ...state.currentPlan,
          box_purchases: [],
          details: {
            ...state.currentPlan.details,
            box_delivery_date: null
          }
        }
      };
    },
    [SET_MOVEPLAN_LOADING]: (state, { payload }) => ({
      ...state,
      loading: payload
    }),
    [SET_DNA_ERROR]: (state, { payload }) => ({
      ...state,
      dnaError: payload
    }),
    [CANCEL_CANCEL]: (state, { payload }) => ({
      ...state,
      cancelCancel: payload
    }),
    [SET_HOURLY_RENDER_LOADING]: (state, { payload }) => ({
      ...state,
      hourlyRenderLoading: payload
    }),
    [REMOVE_HOURLY_FLAG]: state => ({
      ...state,
      currentPlan: {
        ...state.currentPlan,
        hourly_plan: false
      }
    })
  },
  initialState
);

export const totalBoxPurchases = movePlan => sumBy(movePlan.currentPlan.box_purchases, obj => obj.quantity);

export const hasBoxPurchases = movePlan => movePlan.currentPlan.box_purchases.length > 0;

export const showPacking = (auth, movePlan, inventory) => {
  if ((auth.isMover || movePlan.currentPlan.is_done) && !auth.isAdmin) {
    return false;
  }
  if (has(movePlan.currentPlan, 'mover')) {
    return !(
      !!movePlan.currentPlan.read_only_plan ||
      !totalBoxes(inventory) ||
      !!movePlan.currentPlan.mover.additional_services.does_packing
    );
  } else {
    return !(movePlan.currentPlan.read_only_plan || !totalBoxes(inventory));
  }
};

export const showUnpacking = (auth, movePlan, inventory) => {
  if ((auth.isMover || movePlan.currentPlan.is_done) && !auth.isAdmin) {
    return false;
  }
  return !(
    movePlan.currentPlan.read_only_plan ||
    !totalBoxes(inventory) ||
    (has(movePlan.currentPlan, 'mover') ? !!movePlan.currentPlan.mover.additional_services.does_unpacking : false)
  );
};

export const boxDeliveryDisabled = (auth, movePlan) => {
  if (auth.isMover || movePlan.currentPlan.isDone) {
    return true;
  }
  if (!movePlan.validDates.is_at_least_5_business_days_away) {
    return !auth.isAdmin;
  }
  if (has(movePlan.currentPlan, 'mover')) {
    return movePlan.currentPlan.read_only_plan || !movePlan.currentPlan.mover.additional_services.does_box_delivery;
  }

  return !!(movePlan.currentPlan.is_soon || !movePlan.currentPlan.default_box_delivery_date);
};

export const boxDeliveryDisabledReason = (auth, movePlan) => {
  if (movePlan.currentPlan.is_done) {
    return 'Move Plan has already been completed';
  } else if (
    has(movePlan.currentPlan, 'mover') ? movePlan.currentPlan.mover.additional_services.does_box_delivery : false
  ) {
    return "The mover you've selected doesn't support box delivery";
  } else if (auth.isMover) {
    return 'You cannot edit this move plan';
  } else if (has(movePlan.currentPlan, 'details')) {
    if (isAfter(Date.now(), movePlan.currentPlan.details.box_delivery_date)) {
      return 'Date has passed, please contact us if you need to make changes';
    }
  } else if (!!!movePlan.currentPlan.default_box_delivery_date) {
    return 'You cannot order boxes for packing because your move date is less than 5 business days away';
  }
  return undefined;
};

export const isConsultationsDisabled = (auth, movePlan) => auth.isAdmin || movePlan.currentPlan.is_booked;

export const isMoveDateDisabled = (auth, movePlan) => {
  if (auth.isAdmin) {
    return false;
  } else if (has(movePlan.currentPlan, 'details')) {
    let moveDate = new Date(movePlan.currentPlan.details.move_date);
    let now = Date.now();
    if (addYears(moveDate, now) < 2) {
      return true;
    }
  } else return movePlan.currentPlan.is_booked;
};

export const getRescheduleParams = movePlan => {
  let params = {
    move_date: movePlan.currentPlan.details.move_date,
    move_time: movePlan.currentPlan.details.move_time
  };
  if (!!movePlan.currentPlan.details.storage_move_out_date) {
    params.storage_move_out_date = movePlan.currentPlan.details.storage_move_out_date;
  }
  if (!!movePlan.currentPlan.details.box_delivery_date) {
    params.box_delivery_date = movePlan.currentPlan.details.box_delivery_date;
  }
};

export const hasSignifacantBalance = plan => {
  if (plan.is_booked) {
    if (has(plan, 'details')) {
      if (!!plan.details.delivery_estimate)
        return plan.billing.balance >= (plan.pricing.total_cost - plan.billing.balance) * 0.05;
      else return plan.billing.balance >= (plan.pricing.total_cost - plan.billing.balance) * 0.1;
    }
  }
  return false;
};

export const isValidMovedate = (movePlan) => {
  if (has(movePlan.currentPlan, 'details')) {
    return (
      (isSameDay(movePlan.currentPlan.details.move_date, calcTwoBusinessDays()) ||
        isAfter(movePlan.currentPlan.details.move_date, calcTwoBusinessDays())) &&
      isBefore(movePlan.currentPlan.details.move_date, addYears(Date.now(), 1))
    );
  } else return true;
};

export const allExactAddresses = details =>
  !!details.pick_up.street_address &&
  (!!details.drop_off.street_address || details.move_into_storage) &&
  (details.extra_pick_up_enabled ? !!details.extra_pick_up.street_address : true) &&
  (details.extra_drop_off_enabled ? !!details.extra_drop_off.street_address : true);

export const calcTwoBusinessDays = () => {
  let date = Date.now();
  const isDuringHours = getHours(date) < 17;
  const days = isDuringHours ? 2 : 3;
  date = subDays(date, 1);
  for (let day = 0; day <= days; day++) {
    date = addDays(date, 1);
    if (isWeekend(date)) date = addDays(date, 2);
  }
  return date;
};

export const calcMinsToHours = total_mins => {
  let hours = total_mins / 60;
  return Math.round(hours * 10) / 10;
  // let mins = total_mins % 60
  // if (hours === 0) return mins + ' MINUTES'
  // if (hours === 1) return hours + ' HOUR ' + mins + ' MINUTES'
  // return hours + ' HOURS ' + mins + ' MINUTES'
};

export const keepTwoDecimals = price => {
  return Math.round(price * 100) / 100;
};
export default movePlanReducer;
