import { TRACK_TO_SEGMENT, GET_UTM_PARAMS, SET_UTM_PARAMS, PAGE_TO_SEGMENT, SET_COUPON_DATA, SET_INVALID_COUPON } from './../actionTypes';

export const track = (event, data) => ({
  type: TRACK_TO_SEGMENT,
  payload: { event: event, data: data !== undefined ? data : {}}
});

export const page = () => ({
  type: PAGE_TO_SEGMENT
})

export const getUtmParams = () => ({
  type: GET_UTM_PARAMS,
});

export const setUtmParams = params => ({
  type: SET_UTM_PARAMS,
  payload: params
});

export const setCouponData = (data) => ({
  type: SET_COUPON_DATA,
  payload: data
});

export const setInvalidCoupon = (bool) => ({
  type: SET_INVALID_COUPON,
  payload: bool
});