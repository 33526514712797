import React from 'react';
import { useWindowSize } from './Hooks';

const DesktopOnly = ({ children, className }) => {
  let windowSize = useWindowSize();
  return windowSize.innerWidth > 992
    ? <div className={`desktop-only ${className}`}>
        {children}
      </div>
    : null
}
export default DesktopOnly
