import { ACCEPT_JOB, DECLINE_JOB, SET_JOB_DECLINE_INFO, SET_PRICING_OVERRIDE, SET_MOVER_LOADING } from '../actionTypes';

export const acceptJob = () => ({
  type: ACCEPT_JOB
});

export const declineJob = payload => ({
  type: DECLINE_JOB,
  payload: payload
});

export const setJobDeclineInfo = (payload) => ({
  type: SET_JOB_DECLINE_INFO,
  payload: payload
});

export const setPricingOverride = params => ({
  type: SET_PRICING_OVERRIDE,
  payload: params
});

export const setMoverLoading = bool => ({
  type: SET_MOVER_LOADING,
  payload: bool
});