import {
  CHECK_COOKIES,
  CHECK_EMAIL_AVAILABILITY,
  FACEBOOK_LOGIN, GOOGLE_LOGIN, NETWORK_FAILURE,
  NETWORK_SUCCESS,
  REGISTER_USER, RESET_PASSWORD,
  SIGN_IN, SIGN_OUT, CHECK_IS_MOBILE, CHECK_IS_APP
} from '../actionTypes';
import { apiRequest } from '../actions/api';
import {
  setErrorMessage,
  setAuthSubmitting,
  setEmailTaken,
  setUser,
  unsetUser,
  setRole, setResetEmail, setIsApp
} from '../actions/auth';
import { setAuthRedirect } from '../actions/funnel';
import { closeModal } from '../actions/modals';
import has from 'lodash-es/has';
import { setIsMobile } from '../actions/ui/mobile';
import { push } from 'connected-react-router';
import { sendConsultationRequest } from '../actions/consultations';
import { submitBook } from '../actions/book';
import { resetFunnel } from '../actions/funnel';
import { setCurrentMoveplanID } from '../actions/movePlan';

const authMiddleware = ({ getState, dispatch }) => next => action => {
  next(action);

  // todo: verify that this works
  if(action.type === CHECK_EMAIL_AVAILABILITY) {
    dispatch(apiRequest({
      url: `api/users/check_email_availability?user[email]=${action.payload}`,
      method: 'GET',
      fromAction: CHECK_EMAIL_AVAILABILITY
    }))
  }

  if(action.type === REGISTER_USER) {
    let params = {
      user: action.payload,
      newflow: true
    };
    if(getState().movePlan.currentMPID) {
      params.move_plan_id = getState().movePlan.currentMPID
    }
    dispatch(apiRequest({
      url: '/users',
      method: 'POST',
      params: params,
      fromAction: REGISTER_USER,
      callback: !!action.meta.context ? action.meta.context : false
    }))
  }
  if(action.type === SIGN_IN) {
    dispatch(setAuthSubmitting(true));
    let params = {
      user: action.payload,
      newflow: true
    };
    if(getState().movePlan.currentMPID) {
      params.move_plan_id = getState().movePlan.currentMPID
    }
    dispatch(apiRequest({
      url: `/users/login`,
      method: 'POST',
      params: params,
      fromAction: SIGN_IN,
      callback: !!action.meta.consultations ? 'consultations' : false,
      redirect: !!action.meta.redirect ? action.meta.redirect : false
    }))
  }

  if(action.type === SIGN_OUT) {
    // todo: make sure the proper path and method are implemented here
    dispatch(apiRequest({
      url: `/users/logout`,
      method: 'DELETE',
      params: {
        newflow: true
      },
      fromAction: SIGN_OUT
    }));
  }

  if(action.type === CHECK_IS_APP) {
    // TODO: check if this is the application on iOS and set it
    const getUserAgent = () => {
      if(!!navigator) {
        if(!!navigator.userAgent) {
          return navigator.userAgent
        } else if(!!navigator.vendor) {
          return navigator.vendor
        }
      }
      if(!!window.opera) {
        return window.opera
      }
    }
    let userAgent = getUserAgent();
    const isiOSChrome = () => {
      userAgent.match('CriOS')}
		const isiOSFirefox= () => {
      userAgent.match('FxiOS')}
    const isiOS = () => {
      let isiOS = /iPad|iPhone|iPod/.test(userAgent) && !!!window.MSStream
      return isiOS
    }

    if(isiOS() && !(isiOSChrome() || isiOSFirefox())) {
      if(!!window.webkit) {
        if(!!window.webkit.messageHandlers) {
          dispatch(setIsApp(true));
        }
      } else {
        dispatch(setIsApp(false));
      }
    } else {
      dispatch(setIsApp(false));
    }
  }

  if(action.type === FACEBOOK_LOGIN) {
    let fromBooking = action.payload;
    let mpid = getState().movePlan.currentMPID;
    // https://www.facebook.com/login.php
    // ?skip_api_login=1&api_key=1002206536583234&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fv2.12%2Fdialog%2Foauth%3F
    // redirect_uri%3Dhttp%253A%252F%252Flocalhost%253A3000%252Fusers%252Fauth%252Ffacebook%252Fcallback%26state%3Daec96ab7b9b394cbb20f1da87e1e6b925cc04708a0224001%26scope%3Demail%26response_type%3Dcode%26client_id%3D1002206536583234%26ret%3Dlogin%26logger_id%3D06db2fbe-4f20-4ffc-5ae4-1d0c76965abb&cancel_url=http%3A%2F%2Flocalhost%3A3000%2Fusers%2Fauth%2Ffacebook%2Fcallback%3Ferror%3Daccess_denied%26error_code%3D200%26error_description%3DPermissions%2Berror%26error_reason%3Duser_denied%26state%3Daec96ab7b9b394cbb20f1da87e1e6b925cc04708a0224001%23_%3D_&display=page&locale=en_US&logger_id=06db2fbe-4f20-4ffc-5ae4-1d0c76965abb
    let planParam = getState().movePlan.currentMPID ? `&move_plan_id=${mpid}` : '';
    // console.log(` href = ${process.env.REACT_APP_BASE_URL}/users/auth/facebook?redirect_url=${returnUrl(mpid, fromBooking)}${planParam}`)
    window.location.href = `${process.env.REACT_APP_BASE_URL}/users/auth/facebook?redirect_url=${returnUrl(mpid, fromBooking)}${planParam}`
  }

  if(action.type === GOOGLE_LOGIN) {

  }

  if(action.type === CHECK_COOKIES) {
  // %7B%22role%22%3A%22super_admin%22%2C%22name%22%3Anull%2C%22email%22%3A%22superadmin%40unpakt.com%22%7D
    let cookies = action.payload;
    if(has(cookies, 'login_info')) {
      let creds = cookies.login_info;
      dispatch(setUser({ email: creds.email, role: creds.role, name: creds.name }));
      roleHelper(dispatch, creds.role);
    } else { dispatch(setRole(null, false))}
  }

  if(action.type === RESET_PASSWORD) {
    dispatch(apiRequest({
      url: '/users/password.json',
      method: 'POST',
      params: {
        user: {
          email: action.payload
        }
      },
      fromAction: RESET_PASSWORD
    }));
  }

  if( action.type === CHECK_IS_MOBILE) {
    let isMobile = false;
    if(window.innerWidth < 500) {
      isMobile = true;
    } else if (navigator.maxTouchPoints === undefined) {
      // check the user agent info to see if iOs or desktop
      if (!!navigator.userAgent.match(/iPad|iPhone|Android|BlackBerry|Windows Phone|webOS/i)) isMobile = true
      else if (navigator.userAgent.match(/SamsungBrowser/i))  isMobile = true
      else {
        if (navigator.maxTouchPoints > 0 && isMobile === false) {
          // this browser has touch
          if (window.innerWidth <= 1080) {
            // this is most likely a mobile device
            isMobile = true
          } else  isMobile = false
        } 
      }
    }
    dispatch(setIsMobile(isMobile));
  }

  if(action.type === NETWORK_SUCCESS) {
    let { data } = action.payload.response;
    switch (action.meta.originalRequest.fromAction) {
      case CHECK_EMAIL_AVAILABILITY:
        dispatch(setEmailTaken(!data.available));
        break;
      case REGISTER_USER:
        dispatch(setUser(data.user.user));
        if(!!action.meta.originalRequest.callback) {
          if(action.meta.originalRequest.callback === 'book') {
            dispatch(submitBook());
            break;
          } else if(action.meta.originalRequest.callback === 'consultations') {
            dispatch(sendConsultationRequest())
          }
        } else {
          dispatch(closeModal());
        }
        break;
      case SIGN_IN:
        dispatch(setUser(data.user.user));
        roleHelper(dispatch, data.user.user.role);
        dispatch(setAuthSubmitting(false));
        dispatch(closeModal());
        if(action.meta.originalRequest.callback === 'consultations') {
          dispatch(sendConsultationRequest());
        }
        if(!!action.meta.originalRequest.redirect) {
          dispatch(push(action.meta.originalRequest.redirect));
        } else if((data.user.user.role === 'admin' || data.user.user.role === 'super_admin') && !getState().auth.isApp) {
          // TODO: add check if this is iOS app
          window.location.href = process.env.REACT_APP_BASE_API_URL + '/admin';
        } else if(getState().router.location.pathname.includes('moving')) {
          break;
        } else {
          window.location.href = process.env.REACT_APP_SUPPORT_URL + '/my-home';
        }

        break;
      case SIGN_OUT:
        console.log('loging out...')
        dispatch(setRole(null, false));
        dispatch(unsetUser());
        dispatch(setCurrentMoveplanID(false));
        //dispatch(setLastMPID(''));
        document.cookie = 'login_info=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        const isNewDesign = sessionStorage.getItem('newDesign')
        if (isNewDesign) {
          setTimeout(() => dispatch(push('/mobile')), 300);
        } else {
          setTimeout(() => dispatch(push('/')), 300);
        }
        
        
        //window.location.href = process.env.REACT_APP_MARKETING
        break;
      case FACEBOOK_LOGIN:
        //todo: handle facebook login success
        break;
      case RESET_PASSWORD:
        dispatch(setResetEmail(action.meta.originalRequest.params.user.email));
        dispatch(setErrorMessage(null));
        break;
      default:
        break;
    }
  }
  if (action.type === NETWORK_FAILURE) {
    switch (action.meta.originalRequest.fromAction) {
      case SIGN_IN:
        if(!!action.payload.response.response) {
          if(action.payload.response.response.status === 401) {
            dispatch(setErrorMessage('Incorrect username or password'))
          }
        } else {
          dispatch(resetFunnel());
          window.sessionStorage.clear();
        }
        dispatch(setAuthSubmitting(false));
        break;
      case RESET_PASSWORD:
        dispatch(setErrorMessage('Your email was not found.'));
        dispatch(setResetEmail(null));
        break;
      case SIGN_OUT:
        //reset redux
        dispatch(setRole(null, false));
        dispatch(unsetUser());
        //attempt to remove cookie and localstorage
        localStorage.removeItem('persist:root');
        document.cookie = 'login_info=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
        //wait
        const isNewDesign = sessionStorage.getItem('newDesign')
        if (isNewDesign) {
          setTimeout(() => {
            dispatch(push('/mobile'));
            window.location.reload(false);
          }, 300)
        } else {
          setTimeout(() => {
            dispatch(push('/'));
            window.location.reload(false);
          }, 300)
        }

        break;
      default:
        if(has(action.payload.response, 'response') ) {
          if(has(action.payload.response.response, 'status')) {
            if(action.payload.response.response.status === 401) {
              dispatch(setAuthRedirect(getState().router.location.pathname));
              dispatch(push('/sign-in'))
            }
          }
        }
        break;
    }
  }
};

const returnUrl = (mpid, fromBooking) => {
  let base = window.location.origin;
  let state = !!fromBooking ? 'book' : 'plan'
  return mpid ? `${base}/${mpid}/${state}` : `${process.env.REACT_APP_SUPPORT_URL}/my-home`
};

const roleHelper = (dispatch, role) => {
  switch(role) {
    case 'super_admin':
      dispatch(setRole(0)); break;
    case 'admin':
      dispatch(setRole(1)); break;
    case 'mover':
      dispatch(setRole(2));
      break;
    case 'user':
      dispatch(setRole(3));
      break;
    default: dispatch(setRole(4, true)); break;
  }
};

export default authMiddleware;