import { GET_CITY_DATA, SET_MARKETING_LOADING, SET_CITY_DATA, GET_STATE_DATA, SET_STATE_DATA } from '../actionTypes';

export const setMarketingLoading = (bool) => ({
  type: SET_MARKETING_LOADING,
  payload: bool
});

export const getCityData = (stateId, cityId) => ({
  type: GET_CITY_DATA,
  payload: { stateId: stateId, cityId: cityId }
});

export const setCityData = (data) => ({
  type: SET_CITY_DATA,
  payload: data
});

export const getStateData = () => ({
  type: GET_STATE_DATA
});

export const setStateData = (data => ({
  type: SET_STATE_DATA,
  payload: data
}));