import React, { lazy, Suspense, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import DnaFormConcierge from '../common/SidebarDna/DnaFormConcierge';
import DnaContainerConcierge from '../common/SidebarDna/DnaContainerConcierge';
import { openModal } from '../../redux/actions/modals';
import Popover from '../common/Popover';
import UtilitiesConnect from './UtilitiesConnect';
import { track } from '../../redux/actions/analytics';
import { setDnaError } from '../../redux/actions/movePlan';

const AsyncServiceMessages = lazy(() => import("../common/SidebarDna/ServiceMessages"));

const mapStateToProps = ({ movePlan }) => ({
  dnaError: movePlan?.dnaError ? movePlan.dnaError : false
});

const Cover = ({ viewModel, partner, track, dnaError, setDnaError }) => {

  useEffect(() => setDnaError(false), [setDnaError]);
  const [utilView, setUtilView] = useState(false);
  return (
    <>
      {!(viewModel === 'mobile' || viewModel === 'tablet') ? (
        <section
          className={`marketing-page home-cover text-center pt-5 pr-5 pl-5 pb-0 background-pattern ${viewModel} position-relative`}>
          <div className="container cover-box">
            <div className="row mt-4 mb-4">
              <div className="col-2 d-flex align-items-end"></div>

              <div className="col-sm-12 col-md-8 dna-column">
                <h1 className="text-white">Flatrate Network</h1>
                <PartnerCoverText viewModel={viewModel} partner={partner} />

                {utilView && <UtilitiesConnect viewModel={viewModel} />}

                <div className={`${utilView === false ? 'center-container pt-3' : 'd-none'}`}>
                  <p>Book with Flatrate Network, the online moving concierge</p>

                  <div className="p-3">
                    {dnaError ? (
                      <Suspense fallback={''}><AsyncServiceMessages dnaError={dnaError} /></Suspense>
                    ) : (
                      <>
                        <h5 className="pt-3">Instant Prices. Best Deals. Total Protection.</h5>
                        <DnaFormConcierge viewModel={viewModel} formStyle={'new'}>
                          <div className="col-12 p-0">
                            <button className="primary-btn-large h-100 w-100">
                              <h5 className="m-0">Get Prices</h5>
                            </button>
                          </div>
                        </DnaFormConcierge>
                      </>
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-end switch-view">
                  <button onClick={() => setUtilView(!utilView)}>
                    {utilView === false ? (
                      <p className="m-0 pt-1 text-white" onClick={() => track('USER CLICKED UTILITY CONNECT')}>
                        <b>
                          I want to connect utilities &nbsp;
                          <Popover id={'utilities'}>
                            <p>Click here to connect or update your gas, cable, electricity, insurance and more!</p>
                          </Popover>
                        </b>
                      </p>
                    ) : (
                      <p className="m-0 text-white">
                        <b>Back to Moving</b>
                      </p>
                    )}
                  </button>
                </div>
              </div>

              <div className="col-2"></div>
            </div>

          </div>
        </section>
      ) : (
        //MOBILE HEADER
        <section className={`text-center home-cover-small`}>
          <div className={`${'container cover-box mobile-greeting landscape'}`}>
            <h1>
              Flatrate Network
            </h1>
            <PartnerCoverText viewModel={viewModel} partner={partner} />
            <p>Book with Flatrate Network, the online moving concierge</p>
            <p>Instant Prices. Best Deals. Total Protection.</p>
          </div>
          {dnaError ?
            <Suspense fallback={''}><AsyncServiceMessages dnaError={dnaError} /></Suspense>
            :
            <DnaContainerConcierge viewModel={viewModel} />}
        </section>
      )}

      <style jsx>{`
        h1 {
          font-size: 3.5rem;
          line-height: 3.5rem;
          font-weight: 800;
          font-family: ProximaNovaBold, sans-serif;
          text-align: center;
          padding: 1rem 0 0 0;
          text-shadow: 0 13px 27px rgba(50, 50, 93, 0.25);
        }
        p {
          font-size: 1.1rem;
          margin: 0;
          line-height: 1.25;
        }

        .background-pattern {
          background-color: #5cbed9;
        }

        .center-container {
          border-radius: 4px;
          background: #A8B3BE;
          box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3),
            0 -6px 16px -6px rgba(0, 0, 0, 0.025);
        }
        .dna-column {
          z-index: 1;
        }
        .dna-holder {
          background: #f0fcff;
          border-radius: 0 0 3px 3px;
        }
        .dna-holder p {
          margin: 0;
        }

        .primary-btn-large {
          height: 60px;
        }
        .switch-view button {
          background: none;
          border: none;
          border-bottom: 1px solid transparent;
        }
        .switch-view button:hover {
          border-bottom: 1px solid white;
        }

        ///////////// Mobile ///////////////

        .home-cover-small {
          background: rgb(27, 55, 73);
          background: linear-gradient(-187deg, rgb(27, 55, 73) 42%, #27c8ea 42.25%);
        }
        .home-cover-small h1 {
          font-size: 2.4rem;
          line-height: 2.5rem;
          font-weight: 700;
          font-family: 'ProximaNovaBold', sans-serif;
          color: #fff !important;
        }
        .home-cover-small p {
          color: #fff;
          font-size: 1rem;
          line-height: 1.1;
          margin-bottom: 7px;
        }
        .mobile-greeting {
          padding: 1.5rem 1rem;
        }
      `}</style>
    </>
  );
};

const PartnerCoverText = ({ viewModel, partner }) => {
  // PARTNER BRANDED GREETINGS
  let params = new URLSearchParams(window.location.search);
  let greeting = params.get('promo');

  if (greeting === 'NFCAMENITY5' || partner === 'nfcamenity') {
    return (
      <>
        <div className={`marketing-page mb-3 text-white d-flex justify-content-center`}>
          <div className="text-greeting position-relative text-left d-flex align-items-center">
            <h5>Welcome, NFC Amenity Management customers!</h5>
          </div>
        </div>
        <style jsx>{`
          .text-greeting {
            padding-left: 60px;
            min-height: 60px;
          }
          .text-greeting::before {
            content: '';
            display: block;
            width: 50px;
            height: 50px;
            position: absolute;
            top: 0;
            left: 0;
            background: url(${process.env.REACT_APP_CDN_IMAGES}/partners/nfc_logo.png) no-repeat top center / contain;
          }
        `}</style>
      </>
    );
  } else if (greeting === 'EQUIEM10' || partner === 'equiem') {
    return (
      <>
        <>
          <div className={`marketing-page mb-3 text-white d-flex justify-content-center`}>
            <div className="text-greeting position-relative text-left d-flex align-items-center">
              <h5>Welcome, Equiem clients!</h5>
            </div>
          </div>
          <style jsx>{`
            .text-greeting {
              padding-left: 110px;
              min-height: 60px;
            }
            .text-greeting::before {
              content: '';
              display: block;
              width: 100px;
              height: 50px;
              position: absolute;
              top: 0;
              left: 0;
              background: url(${process.env.REACT_APP_CDN_IMAGES}/partners/equiem_logo.png) no-repeat center center /
                contain;
            }
          `}</style>
        </>
      </>
    );
  } else if (greeting === 'utilityconnect' || partner === 'utilityconnect') {
    return (
      <>
        <>
          <div className={`marketing-page mb-3 text-white d-flex justify-content-center`}>
            <div className="text-greeting position-relative text-left d-flex align-items-center">
              <h5>Welcome, Utility Connect clients!</h5>
            </div>
          </div>
          <style jsx>{`
            .text-greeting {
              padding-left: 220px;
              min-height: 60px;
              color: #183244
            }
            .text-greeting::before {
              content: '';
              display: block;
              width: 200px;
              height: 50px;
              position: absolute;
              top: 0;
              left: 0;
              background: url(${process.env.REACT_APP_CDN_IMAGES}/partners/utility_connect.png) no-repeat center center /
                contain;
            }
          `}</style>
        </>
      </>
    );
  }
  else {
    return <></>;
  }
};

export default connect(
  mapStateToProps,
  { openModal, track, setDnaError }
)(Cover);
