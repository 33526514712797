import {
  SET_SUGGESTIONS,
  SELECT_SUGGESTION,
  SET_SCRIPT_LOADING,
  SET_SELECTED_DNA_TAB,
  SET_PPC_NYC_SELECTED_DNA_TAB,
  SET_PREV_PICK_UP_ADDRESS,
  SET_PREV_DROP_OFF_ADDRESS,
  SET_DNA_LOADING
} from '../actionTypes';
import { handleActions } from 'redux-actions';

const initialState = {
  suggestions: [],
  selected: {
    pick_up: {},
    drop_off: {}
  },
  scriptLoading: false,
  dnaLoading: false,
  selectedDNAtab: 'hourly',
  selectedPpcNycDNAtab: 'regular',
  prevPickUpAddress: null,
  prevDropOffAddress: null
};

const commonReducer = handleActions(
  {
    [SET_SUGGESTIONS]: (state, { payload }) => ({
      ...state,
      suggestions: payload
    }),
    [SELECT_SUGGESTION]: (state, { payload }) => ({
      ...state,
      selected: {
        ...state.selected,
        [payload.key]: payload.suggestion
      }
    }),
    [SET_SCRIPT_LOADING]: (state, { payload }) => ({
      ...state,
      scriptLoading: payload
    }),
    [SET_DNA_LOADING]: (state, { payload }) => ({
      ...state,
      dnaLoading: payload
    }),
    [SET_SELECTED_DNA_TAB]: (state, { payload }) => ({
      ...state,
      selectedDNAtab: payload
    }),
    [SET_PPC_NYC_SELECTED_DNA_TAB]: (state, { payload }) => ({
      ...state,
      selectedPpcNycDNAtab: payload
    }),
    [SET_PREV_PICK_UP_ADDRESS]: (state, { payload }) => ({
      ...state,
      prevPickUpAddress: payload
    }),
    [SET_PREV_DROP_OFF_ADDRESS]: (state, { payload }) => ({
      ...state,
      prevDropOffAddress: payload
    })
  },
  initialState
);

export default commonReducer;
