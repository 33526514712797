import { handleActions } from 'redux-actions';
import {TIME_OPTIONS_SELECT} from "../../constants";
import {
  SELECT_CONSULTATION_MOVER,
  SET_CONSULTATION_DATE, SET_CONSULTATION_TIME,
  SET_CONSULTATIONS_MOVER, SET_CONTACT_INFO, SET_NO_MOVERS_FOUND, SET_POTENTIAL_MOVERS, TOGGLE_SUBMITTING,
  TOGGLE_CONSULTATIONS_EDIT_MODE, SET_CONSULTATIONS_TYPE, SET_ONSITE_REQUESTS, SET_CONSULTATIONS_LOADING, RESET_CONSULTATIONS
} from "../actionTypes";
import { isSameDay, format } from 'date-fns';
import { calcTwoBusinessDays } from './movePlan';

const defaultDate = calcTwoBusinessDays();

const initialState = {
  options: [
    {
      editMode: true,
      mover: undefined,
      date: defaultDate,
      time: TIME_OPTIONS_SELECT[0].value,
      scheduled: false,
      id: 'schedule0',
      noMoversFound: false,
      movers: []
    },
    {
      editMode: false,
      mover: undefined,
      date: defaultDate,
      time: TIME_OPTIONS_SELECT[1].value,
      scheduled: false,
      id: 'schedule1',
      noMoversFound: false,
      movers: []
    },
    {
      editMode: false,
      mover: undefined,
      date: defaultDate,
      time: TIME_OPTIONS_SELECT[2].value,
      scheduled: false,
      id: 'schedule2',
      noMoversFound: false,
      movers: []
    }
  ],
  contact: {
    name: '',
    phone_number: '',
    street_address: '',
    apartment_number: ''
  },
  submitting: false,
  type: '',
  onsite_requests: [],
  loading: [false,false,false]
}; 

const consultationsReducer = handleActions({
  [TOGGLE_CONSULTATIONS_EDIT_MODE]: (state, action) => ({
    ...state,
    options: state.options.map(
      (option, index) => index === action.payload ? {
          ...option,
          editMode: !state.options[action.payload].editMode
      } : option
    )
  }),
  [SET_CONSULTATIONS_MOVER]: (state, action) => ({
    ...state,
    options: state.options.map(
      (option,index) => index === action.payload.index ?  {
        ...option,
        mover: action.payload.mover,
        scheduled: true
      } : option
    )
  }),
  [SET_CONSULTATION_DATE]: (state, action) => ({
    ...state,
    options: state.options.map(
      (option,index) => index === action.payload.index ?  {
        ...option,
        date: action.payload.date
      } : option
    )
  }),
  [SET_CONSULTATION_TIME]: (state, action) => {

    return ({
    ...state,
      options: state.options.map(
      (option, index) => index === action.payload.index ?  {
        ...option,
        time: action.payload.time
      } : option
    )
    });
  },
  [SET_CONTACT_INFO]: (state, action) => ({
    ...state,
    contact: action.payload
  }),
  [TOGGLE_SUBMITTING]: (state) => ({
    ...state,
    submitting: !state.submitting
  }),
  [SET_POTENTIAL_MOVERS]: (state, action) => ({
    ...state,
      options: state.options.map(
        (option, index) => index === action.payload.index ?  {
          ...option,
          movers: action.payload.movers
        } : option)
  }),
  [SELECT_CONSULTATION_MOVER]: (state, action) => ({
    ...state,
    options: state.options.map(
      (option, index) => index === action.payload.index ?  {
        ...option,
        mover: action.payload.mover,
        editMode: false
      } : option
    )
  }),
  [SET_NO_MOVERS_FOUND]: (state, action) => ({
    ...state,
    options: state.options.map(
      (option, index) => index === action.payload.index ?  {
        ...option,
        noMoversFound: action.payload.noMoversFound
      } : option
    )
  }),
  [SET_CONSULTATIONS_TYPE]: (state, action) => ({
    ...state,
    type: action.payload
  }),
  [SET_ONSITE_REQUESTS]: (state, action) => ({
    ...state,
    onsite_requests: action.payload
  }),
  [SET_CONSULTATIONS_LOADING]: (state, {payload}) => ({
    ...state,
    loading: payload
  }),
  [RESET_CONSULTATIONS]: (state, action) => ({
    ...state,
    options: [
      {
        editMode: true,
        mover: undefined,
        date: defaultDate,
        time: TIME_OPTIONS_SELECT[0].value,
        scheduled: false,
        id: 'schedule0',
        noMoversFound: false,
        movers: []
      },
      {
        editMode: false,
        mover: undefined,
        date: defaultDate,
        time: TIME_OPTIONS_SELECT[1].value,
        scheduled: false,
        id: 'schedule1',
        noMoversFound: false,
        movers: []
      },
      {
        editMode: false,
        mover: undefined,
        date: defaultDate,
        time: TIME_OPTIONS_SELECT[2].value,
        scheduled: false,
        id: 'schedule2',
        noMoversFound: false,
        movers: []
      }
    ]
  })
}, initialState);

export const hasSameDateTime = (options) => (
  [0,1,2].map(item => (
    options.map( (option, i) => {
      if (i !== item) {
        return ((options[i].time === !!options[item].time)) && (options[i].time === options[item].time) &&
          ( isSameDay(options[i].date, options[item].date));
      } else return null
    })
  ))
);

export const isConsultationTimeTaken = (consultation) => (
  consultation.options.some( option => (
    consultation.onsite_requests.some( request => request.time === option.time)
  )) &&
  consultation.options.some( option => (
    consultation.onsite_requests.some( request => request.date === format(new Date(option.date), "MM/DD/YYYY"))
  ))
);




export default consultationsReducer;