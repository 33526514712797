import {
  HIDE_LEFT_MENU_TOGGLE,
  HIDE_RIGHT_MENU_TOGGLE,
  SHOW_LEFT_MENU_TOGGLE,
  SHOW_RIGHT_MENU_TOGGLE,
  TOGGLE_LEFT_MENU,
  TOGGLE_RIGHT_MENU,
  TOGGLE_STEPS_MENU,
  HIDE_ALL_MENUS, TOGGLE_MOBILE_NAV,
  CHECK_IS_MOBILE,
  SET_IS_MOBILE,
} from '../../actionTypes';

export const showLeftMenuToggle = () => ({
  type: SHOW_LEFT_MENU_TOGGLE
});

export const hideLeftMenuToggle = () => ({
  type: HIDE_LEFT_MENU_TOGGLE
});

export const showRightMenuToggle = () => ({
  type: SHOW_RIGHT_MENU_TOGGLE
});

export const hideRightMenuToggle = () => ({
  type: HIDE_RIGHT_MENU_TOGGLE
});

export const toggleLeftMenu = () => ({
  type: TOGGLE_LEFT_MENU
});

export const toggleRightMenu = () => ({
  type: TOGGLE_RIGHT_MENU
});

export const toggleStepsMenu = () => ({
  type: TOGGLE_STEPS_MENU
});

export const hideAllMenus = () => ({
  type: HIDE_ALL_MENUS
});

export const toggleMobileNav = () => ({
  type: TOGGLE_MOBILE_NAV
});
export const checkIsMobile = (bool) => ({
  type: CHECK_IS_MOBILE,
  payload: bool
});

export const setIsMobile = (bool) => ({
  type: SET_IS_MOBILE,
  payload: bool
});
