import { handleActions } from 'redux-actions';
import {
  SET_ADMIN_NOTES,
  SET_EMAIL_ERROR,
  SET_EMAIL_SENT, SET_HISTORY, SET_HISTORY_UPDATING, SET_MOVER_NOTES, SET_NOTES_UPDATING,
  SET_NOTIFICATION_DISMISSED, SET_PLAN_UPDATING,
  SET_ROUTES,
  SET_SELECTED_TAB_PLAN,
  SET_SHOW_STORAGE,
  TOGGLE_STORAGE_PLAN, UPDATE_COUPON, SET_ADJUSTMENT_PAYLOAD, SET_ADJUSTMENT_LOADING, SET_MDA_SENDING, SET_MDA_STATUS
} from '../actionTypes';

const initialState = {
  notificationDismissed: false,
  selectedTab: 'myMoveplan',
  emailError: false,
  emailSent: false,
  routes: {},
  showStorage: false,
  planUpdating: false,
  history: [],
  historyUpdating: false,
  notesUpdating: false,
  moverNotes: [],
  adminNotes: [],
  adjustments: [],
  adjustmentLoading: false,
  coupon: {
    couponInvalid: false,
    loading: false,
    couponApplied: false,
    promoCode: ''
  },
  printing: false,
  adjustmentPayload: {},
  prevTab: false,
  mdaSending: false,
  mdaStatus: {
    email: false,
    sms: false
  }
};


const planReducer = handleActions({
    [SET_NOTIFICATION_DISMISSED]: state => ({
      ...state,
      notificationDismissed: true
    }),
    [SET_SELECTED_TAB_PLAN]: (state, {payload}) => ({
      ...state,
      selectedTab: payload,
      prevTab: state.selectedTab
    }),
    [SET_EMAIL_ERROR]: (state, {payload}) => ({
      ...state,
      emailError: payload
    }),
    [SET_EMAIL_SENT]: (state, {payload}) => ({
      ...state,
      emailSent: payload
    }),
    [SET_ROUTES]: (state, {payload}) => ({
      ...state,
      routes: payload
    }),
    [TOGGLE_STORAGE_PLAN]: state => ({
      ...state,
      showStorage: !state.showStorage
    }),
    [SET_SHOW_STORAGE]: (state, {payload}) => ({
      ...state,
      showStorage: payload
    }),
    [SET_PLAN_UPDATING]: (state, {payload}) => ({
      ...state,
      planUpdating: payload
    }),
    [SET_HISTORY_UPDATING]: (state, {payload}) => ({
      ...state,
      historyUpdating: payload
    }),
    [SET_HISTORY]: (state, {payload}) => ({
      ...state,
      history: payload
    }),
    [SET_NOTES_UPDATING]: (state, {payload}) => ({
      ...state,
      notesUpdating: payload
    }),
    [SET_MOVER_NOTES]: (state, {payload}) => ({
      ...state,
      moverNotes: payload
    }),
    [SET_ADMIN_NOTES]: (state, {payload}) => ({
      ...state,
      adminNotes: payload
    }),
    [UPDATE_COUPON]: (state, {payload}) => ({
      ...state,
      coupon: {
        ...state.coupon,
        [payload.key]: payload.value
      }
    }),
    [SET_ADJUSTMENT_LOADING]: (state, {payload}) => ({
      ...state,
      adjustmentLoading: payload
    }),
    [SET_ADJUSTMENT_PAYLOAD]: (state, { payload }) => ({
      ...state,
      adjustmentPayload: payload
    }),
    [SET_MDA_SENDING]: (state, { payload }) => ({
      ...state,
      mdaSending: payload
    }),
    [SET_MDA_STATUS]: (state, { payload }) => ({
      ...state,
      mdaStatus: payload
    })
  },
  initialState
);

export default planReducer;
