import React from 'react';

const Numbers = ({ viewModel }) => (
  
  <section className="press-and-numbers pb-4 pt-4">
    <div className="marketing-page container ">
      <div className={`row d-flex align-items-center p-2 numbers ${viewModel}`}>
        <div className={`col-4  mt-1 mb-1 ${viewModel === 'wide' ? 'd-flex' : 'text-center'}`}>
          <div className="m-auto icon-numbers moves"></div>
          <div className="d-flex justify-content-center flex-column"> 
           <p className="color-red mt-2 mb-1 numbers__num">800,000+</p>
           <p className={`${viewModel === 'mobile' && 'numbers__text'}`}>moves booked {viewModel !== 'mobile' && ' through Flatrate Network'}</p>
          </div>
        </div>
        <div className={`col-4  mt-1 mb-1 ${viewModel === 'wide' ? 'd-flex' : 'd-flex flex-column align-items-center text-center'}`}>
          <div className=" icon-numbers mr-4 ml-4 miles"></div>
          <div className="d-flex justify-content-center flex-column"> 
            <p className="color-red mt-2 mb-1 numbers__num">4Million+</p>
            <p className={`${viewModel === 'mobile' && 'numbers__text'}`}>miles moved {viewModel !== 'mobile' && ' across USA'}</p>
          </div>
        </div>
        <div className={`col-4  mt-1 mb-1 ${viewModel === 'wide' ? 'd-flex' : 'd-flex flex-column align-items-center text-center'}`}>
          <div className=" icon-numbers mr-4 ml-4 lbs"></div>
          <div className="d-flex justify-content-center flex-column"> 
            <p className="color-red mt-2 mb-1 numbers__num">70Million+</p>
            <p className={`${viewModel === 'mobile' && 'numbers__text'}`}>lbs moved {viewModel !== 'mobile' && ' by our movers'}</p>
          </div>
        </div>
      </div>
      {/* <div className="col-12 text-center text-white mt-3 mb-1">
          <h5>Flatrate Network delivers a moving experience</h5>
        </div> */}
    </div>                                        
    <style jsx>{ `

      .press-and-numbers {
        background: #e23337;
        background: ${viewModel === 'wide' || viewModel === 'desktop' ? 'transparent' : '#e23337'};
      }
      .numbers {
        box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);
        border-radius: 5px;
        background: white;
      }
      .numbers h3, .numbers p, .numbers h3 {
        line-height: 1;
        margin: 0
      }
      .numbers.mobile h3, .numbers.mobile h3 {
        font-size: 2vh;
      }
      .numbers__num {
        font-size: 2.7vh;
        font-weight: bold;
        font-family: "ProximaNovaBold", sans-serif;
      }
      .numbers__text {
        font-size: 0.9rem;
        letter-spacing: -0.4px;
      }
      @media (max-width: 330px) { 
        .numbers__text {
          font-size: 2vh;
        }
      }
      .color-red {
        color: #e23337
      }
      .icon-numbers {
        width: ${viewModel === 'desktop' || viewModel === 'wide' ? '100px' : '70px'};
        height: ${viewModel === 'desktop' || viewModel === 'wide' ? '80px' : '60px'};
      }
      .moves {
        background: url(${process.env.REACT_APP_CDN_IMAGES}/non-funnel/services/ic_international.svg) no-repeat center center / 90%;
      }
      .miles {
        background: url(${process.env.REACT_APP_CDN_IMAGES}/home/ic_map.svg) no-repeat center center;

      }
      .lbs {
        background: url(${process.env.REACT_APP_CDN_IMAGES}/home/cart.svg) no-repeat center center ;
      }
     
    `}</style>
  </section>
);

export default Numbers