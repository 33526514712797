import React from 'react';
import { connect } from 'react-redux';
import { gotoPlan } from '../../redux/actions/funnel';
import { openModal } from '../../redux/actions/modals';
import { setLastMPID } from '../../redux/actions/auth';
import SaveItForLaterPop from './PlanSidebar/SaveItForLaterPop';
import { continueLastPlan } from '../../redux/actions/movePlan';

const mapStateToProps = ({ auth, movePlan }) => ({
  isMover: auth.isMover,
  isAdmin: auth.isAdmin || auth.isSuperAdmin,
  isLoggedIn: auth.user,
  mover: movePlan.currentPlan.mover,
  movePlan: movePlan.currentPlan.details,
  lastMPID: auth.lastMPID
});

const ContinuePlanOptions = ({
  mover,
  isAdmin,
  movePlan,
  isLoggedIn,
  openModal,
  gotoPlan,
  lastMPID,
  continueLastPlan,
  viewModel
}) => (
  <>
    {!!lastMPID && (
      <div
        className={`row continue-last-plan m-0 p-0 d-flex lead-text  ${
          viewModel === 'mobile' ? 'justify-content-center flex-column align-items-center' : 'justify-content-between'
        }`}>
        <div>
          <div onClick={() => continueLastPlan()} className="link">
            Continue Last Move Plan
          </div>
        </div>

        <div className={`d-flex ${viewModel === 'mobile' && 'flex-column text-center align-items-center'}`}>
          {movePlan && (
            <div>
              {movePlan.pick_up.city} to {movePlan.drop_off.city} on {movePlan.move_date} -{' '}
            </div>
          )}
          <ul className="d-flex mb-0">
            {(isAdmin || !!mover) && (
              <li className="item view">
                <button style={{ cursor: 'pointer' }} className="link" onClick={() => gotoPlan()}>
                  <svg className="icon icon-eye">
                    <use xlinkHref="#icon-eye" />
                  </svg>
                  <span className="text">View</span>
                </button>
              </li>
            )}
            <li className="item email">
              <button
                onClick={() => openModal({ name: 'emailPlan', properties: {} })}
                className="link"
                title="Send Email">
                <svg className="icon icon-email">
                  <use xlinkHref="#icon-email" />
                </svg>
                <span className="text">Email</span>
              </button>
            </li>
            <li className="item save">
              <button className="link">
                {!!!isLoggedIn && (
                  <div className="d-flex">
                    <svg className="icon icon-save">
                      <use xlinkHref="#icon-save" />
                    </svg>
                    <SaveItForLaterPop />
                  </div>
                )}
                {!!isLoggedIn && <span className="saved">Saved! </span>}
              </button>
            </li>
          </ul>
        </div>
      </div>
    )}

    {!!!lastMPID && (
      <div className="mt-3 mb-1 lead-text">
        <p className="m-0">Compare Prices and Movers Now!</p>
      </div>
    )}

    <style jsx>{`
        .continue-last-plan {
          text-shadow: 2px 2px 3px #00000050;
        }
        .lead-text {
          font-weight: bold;
          color: white;
        }
        .link {
          border-bottom: 1px solid transparent;
        }
        button {
          border: none;
          background-color: transparent;
          padding: 0 0.25rem;
        }
        svg {
          fill: #fff;
        }
        .link:hover {
          color: #e23337;
        }

        .text {
          margin-left: 0.25rem;
          color: #fff;
          text-shadow: 2px 2px 2px black;
        }
        :global(.item.save #saveItForLaterPop),
        :global(.item.save .saved) {
          background: none;
          border: none;
          color: #fff !important;
          font-family: ProximaNovaRegular, sans-serif;
          font-size: inherit;
          margin: 0;
          padding: 0;
          box-shadow: none;
          width: auto;
          height: 19px;
          line-height: 1;
          text-shadow: 2px 2px 2px black;
        }
        :global(.save .saved):before {
          content: '✔';
          margin-right: 3px;
        }
        .icon-save {
          margin-top: 1px;
        }
        :global(.bs-popover-bottom) {
          display: none;
        }
    `}</style>
  </>
);

export default connect(
  mapStateToProps,
  { setLastMPID, continueLastPlan, openModal, gotoPlan }
)(ContinuePlanOptions);
