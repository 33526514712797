import React from 'react';
import { connect } from 'react-redux';
import { toggleMobileNav } from '../../../redux/actions/ui/mobile';

const MobileDimmer = ({handleToggle}) => (
  <div className="dimmer" onClick={() => handleToggle()}>
    <style jsx>{`
    .dimmer {
      position: fixed;
      top: 70px;
      height: 100%;
      opacity: 1;
      background-color: rgba(0,0,0,.5);
      -webkit-transition: opacity .2s;
      -o-transition: opacity .2s;
      transition: opacity .2s;
      z-index: 4;
      max-width: 1000px;
      width: 100%;
      margin: 0;
      padding: 0;
    }
  `}</style>
  </div>
);

export default connect(null, {toggleMobileNav})(MobileDimmer)
