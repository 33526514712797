import { handleActions } from 'redux-actions';
import {
  HIDE_ALL_MENUS,
  HIDE_LEFT_MENU_TOGGLE,
  HIDE_RIGHT_MENU_TOGGLE,
  SHOW_LEFT_MENU_TOGGLE,
  SHOW_RIGHT_MENU_TOGGLE,
  TOGGLE_LEFT_MENU, TOGGLE_MOBILE_NAV,
  TOGGLE_RIGHT_MENU,
  TOGGLE_STEPS_MENU,
  SET_IS_MOBILE
} from '../../actionTypes';

export const initialState = {
  leftMenuToggleVisible: false,
  rightMenuToggleVisible: false,
  leftMenuVisible: false,
  rightMenuVisible: false,
  stepsMenuVisible: false,
  showPlanSidebarRight: true,
  showMobileNav: false,
  isMobile: false
};

const mobileReducer = handleActions(
  {
    [SHOW_LEFT_MENU_TOGGLE]: state => ({
      ...state,
      leftMenuToggleVisible: true
    }),

    [HIDE_LEFT_MENU_TOGGLE]: state => ({
      ...state,
      leftMenuToggleVisible: false
    }),

    [SHOW_RIGHT_MENU_TOGGLE]: state => ({
      ...state,
      rightMenuToggleVisible: true
    }),

    [HIDE_RIGHT_MENU_TOGGLE]: state => ({
      ...state,
      rightMenuToggleVisible: false
    }),

    [TOGGLE_LEFT_MENU]: state => ({
      ...state,
      leftMenuVisible: !state.leftMenuVisible
    }),

    [TOGGLE_RIGHT_MENU]: state => ({
      ...state,
      rightMenuVisible: !state.rightMenuVisible
    }),

    [TOGGLE_STEPS_MENU]: state => ({
      ...state,
      stepsMenuVisible: !state.stepsMenuVisible
    }),

    [HIDE_ALL_MENUS]: state => ({
      ...state,
      stepsMenuVisible: false,
      leftMenuVisible: false,
      rightMenuVisible: false
    }),
    [TOGGLE_MOBILE_NAV]: state => ({
      ...state,
      showMobileNav: !state.showMobileNav
    }),
    [SET_IS_MOBILE]: (state, {payload}) => ({
      ...state,
      isMobile: payload
    })
  },
  initialState
);

export default mobileReducer;
