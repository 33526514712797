import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from "connected-react-router";
import history from './redux/history';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import store from './redux/store';
import { persistStore } from "redux-persist";
import { CookiesProvider } from 'react-cookie';
import * as serviceWorker from './serviceWorker';

let persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'));

serviceWorker.register();
export default persistor
