import React from 'react';
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import ScriptLoadContainer from '../../common/ScriptLoadContainer';
import { format } from 'date-fns';
import { has } from 'lodash-es';
import { submitDNA } from '../../../redux/actions/movePlan';
import { openModal } from '../../../redux/actions/modals';
import { useSelector, useDispatch } from 'react-redux';
import { HOME_SIZES_MAPPING_FROM_MM, TIME_FRAMES, formatFloorAndBuildingType } from '../../../constants';
import { setBookContact } from '../../../redux/actions/book';
import { setDnaLoading } from '../../../redux/actions/common';

const formatApartmentNumber = aptNumber => {
  if (aptNumber === undefined || aptNumber === null || aptNumber === 'N/A' || aptNumber.trim() === '') {
    return null;
  } else {
    return aptNumber.trim();
  }
};

function mapTimeToInterval(moveDateStr) {
  // Parse the moveDate to get the date in UTC
  const moveDate = new Date(moveDateStr);
  var offsetInHours = -3; //Convert it to EST
  moveDate.setHours(moveDate.getHours() + offsetInHours);
  const hour = moveDate.getUTCHours();
  // Define the time intervals
  const intervals = [
    { start: 8, end: 10, label: TIME_FRAMES[0].full_time },
    { start: 9, end: 11, label: TIME_FRAMES[1].full_time },
    { start: 12, end: 15, label: TIME_FRAMES[2].full_time },
    { start: 14, end: 16, label: TIME_FRAMES[3].full_time }
  ];

  // Find the matching interval or return the closest based on the time
  let intervalLabel = intervals[0].label; // Default to the first interval

  for (let i = 0; i < intervals.length; i++) {
    if (hour >= intervals[i].start && hour < intervals[i].end) {
      intervalLabel = intervals[i].label;
      break;
    } else if (hour < intervals[0].start) {
      intervalLabel = intervals[0].label;
      break;
    } else if (hour >= intervals[intervals.length - 1].end) {
      intervalLabel = intervals[intervals.length - 1].label;
      break;
    }
  }

  return intervalLabel;
}

const formatAddress = (suggestion, addressObj) => {
  let components = {};
  suggestion.address_components.map(value => (components[value.types[0]] = [value.long_name, value.short_name]));
  if (components.locality === 'Washington' && components.administrative_area_level_1 === 'District of Columbia') {
    components.locality[0] = components.administrative_area_level_1.long_name;
    components.locality[1] = components.administrative_area_level_1.short_name;
  }
  let address = {};
  try {
    address = {
      lat: suggestion.geometry.location.lat(),
      lng: suggestion.geometry.location.lng(),
      geocoded_address: suggestion.formatted_address,
      street_address:
        !!components['street_number'] && components['route']
          ? components['street_number'][0] + ' ' + components['route'][0]
          : null,
      city:
        !has(components, 'locality') &&
        !has(components, 'administrative_area_level_3') &&
        !has(components, 'administrative_area_level_1')
          ? components['sublocality_level_1'][0]
          : has(components, 'locality')
          ? components['locality'][0]
          : has(components, 'administrative_area_level_3')
          ? components['administrative_area_level_3'][0]
          : components['administrative_area_level_2'][0],
      state: components['administrative_area_level_1'][1],
      zip: !!components['postal_code'] ? components['postal_code'][0] : null,
      apartment_number: formatApartmentNumber(addressObj.apartment_number),
      height_id: formatFloorAndBuildingType({
        buildingType: addressObj.buildingType1,
        flightNumber: addressObj.flightNumber
      }),
      certificate_of_insurance_required: addressObj.certificate_of_insurance_required
    };
  } catch {
    let addressArray = suggestion?.label?.split(', ') || [];
    if (addressArray.length > 2) {
      address = {
        lat: suggestion.geometry.location.lat(),
        lng: suggestion.geometry.location.lat(),
        geocoded_address: suggestion.description,
        street_address: addressArray[0],
        city: addressArray[1],
        state: addressArray[2],
        zip: components['postal_code'],
        apartment_number: formatApartmentNumber(addressObj.apartment_number),
        height_id: formatFloorAndBuildingType({
          buildingType: addressObj.buildingType1,
          flightNumber: addressObj.flightNumber
        }),
        certificate_of_insurance_required: addressObj.certificate_of_insurance_required
      };
    }
  }
  return address;
};

const formatAddressString = addressObj => {
  let fullAddressArr = [];

  if (addressObj.address && addressObj.address.toUpperCase() !== 'TBD') {
    fullAddressArr.push(addressObj.address);
  }
  if (addressObj.city && addressObj.city.toUpperCase() !== 'TBD') {
    fullAddressArr.push(addressObj.city);
  }
  if (addressObj.state && addressObj.state.toUpperCase() !== 'TBD') {
    fullAddressArr.push(addressObj.state);
  }

  return fullAddressArr.join(', ');
};

const getAddressInformation = address => {
  const fullAddress = formatAddressString(address);
  return new Promise((resolve, reject) => {
    try {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: fullAddress }, (results, status) => {
        if (status === 'OK') {
          resolve(formatAddress(results[0], address));
        } else {
          console.error('Geocode was not successful: ' + status);
          reject('Geocode was not successful: ' + status);
        }
      });
    } catch (e) {
      console.error(e.message);
      reject(e.message);
    }
  });
};

const adaptAddresses = data => {
  const isThereExtraDropOffAddress = data.addresses.length > 2;
  const addressLength = data.addresses.length - 1;
  const pickUpAddress = data.addresses[0];
  const extraDropOffAddress = isThereExtraDropOffAddress ? data.addresses[addressLength] : {};
  const dropOffAddress = isThereExtraDropOffAddress ? data.addresses[addressLength - 1] : data.addresses[addressLength];

  const pickUpAddressObject = {
    address: pickUpAddress.address,
    city: pickUpAddress.city,
    state: pickUpAddress.state,
    apartment_number: pickUpAddress.apartmentNumber,
    buildingType1: pickUpAddress.buildingType1,
    flightNumber: pickUpAddress.flightNumber,
    certificate_of_insurance_required: pickUpAddress.COIRequired
  };

  let extraDropOffObject = {};
  if (Object.keys(extraDropOffAddress).length > 0) {
    extraDropOffObject = {
      address: extraDropOffAddress.address,
      city: extraDropOffAddress.city,
      state: extraDropOffAddress.state,
      apartment_number: extraDropOffAddress.apartmentNumber,
      buildingType1: extraDropOffAddress.buildingType1,
      flightNumber: extraDropOffAddress.flightNumber,
      certificate_of_insurance_required: extraDropOffAddress.COIRequired
    };
  }

  const dropOffAddressObject = {
    address: dropOffAddress.address,
    city: dropOffAddress.city,
    state: dropOffAddress.state,
    apartment_number: dropOffAddress.apartmentNumber,
    buildingType1: dropOffAddress.buildingType1,
    flightNumber: dropOffAddress.flightNumber,
    certificate_of_insurance_required: dropOffAddress.COIRequired
  };

  return {
    pickUpObject: pickUpAddressObject,
    extraDropOffObject: extraDropOffObject,
    dropOffObject: dropOffAddressObject
  };
};

const fetchMoveIdInfo = async ({ frAuth, frMoveId }) => {
  return await fetch(
    `${process.env.REACT_APP_BASE_MM_API}/getMoveById/?quickDebugKey=lkajsdnf%24sdgn1432%24%40b%24SG`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'fr-auth': frAuth,
        'x-mock-response-name': 'getMoveById_example1',
        'x-api-key': 'd2febe0f4f7f4d09bba37e22f4fc68bc',
        debugSleep: '0',
        Cookie:
          'ASP.NET_SessionId=itrb3o4omjc4wiikxkhtvaxs; visitorSource=4B974AAF49592EFCFC005EC8DC02077A918A8583FBCFFC1C838E2F9C3949AE1C81D313FE2E29F0B6BE3471C7D28532E2D2121CF5106338BF2E8AD903AF1F0E5AF3B08E4C2916AAAA4D9A54EDB049CB4C8493DBA21DD76599879D8640497E52A9F2C8AD5C591CE10CDF1E8DBE890BF08EFD9522B81F6EB9C579841193BE30A04E'
      },
      body: JSON.stringify({ moveId: frMoveId.trim() })
    }
  ).then(async response => {
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  });
};

const DnaFormSalesRep = () => {
  const queryParams = new URLSearchParams(useLocation().search);
  const moveid = decodeURIComponent(queryParams.get('moveid') || '');
  const frAuth = decodeURIComponent(queryParams.get('frauth') || '');

  const { isAdmin, isSuperAdmin, user, isMover } = useSelector(state => state.auth);
  const { dnaLoading } = useSelector(state => state.common);
  const dispatch = useDispatch();
  const isLoggedIn = user !== undefined;

  const getPlanInfoHandler = async event => {
    event.preventDefault();
    // check whether the user is logged in as an admin or superadmin and it's not a mover.
    if (!(isLoggedIn && (isAdmin || isSuperAdmin) && !isMover)) {
      dispatch(openModal({ name: 'authModal', properties: { authModalView: 'login' } }));
      return;
    }

    if (!moveid || !frAuth) {
      dispatch(
        openModal({
          name: 'apiError',
          properties: { error: 'Something went wrong trying to get move plan information.' }
        })
      );
      return;
    }

    let data = null;
    const formData = new FormData(event.target);
    const frMoveId = formData.get('moveId');
    dispatch(setDnaLoading(true));

    //Fetch MM move ID info
    try {
      data = await fetchMoveIdInfo({ frMoveId, frAuth });
    } catch (error) {
      dispatch(
        openModal({
          name: 'apiError',
          properties: { error: 'Something went wrong trying to get move plan information.' }
        })
      );
      dispatch(setDnaLoading(false));
      console.error('Error fetching data: ', error);
      return;
    }
    // FETCH PICKUP AND DROP OFF ADDRESSES FROM GMAPS
    let pickUpInformation,
      extraDropOffInformation,
      dropOffInformation = null;
    const { pickUpObject, extraDropOffObject, dropOffObject } = adaptAddresses(data);

    if (window.google && window.google.maps) {
      try {
        if (Object.keys(extraDropOffObject).length > 0) {
          [pickUpInformation, dropOffInformation, extraDropOffInformation] = await Promise.all([
            getAddressInformation(pickUpObject),
            getAddressInformation(dropOffObject),
            getAddressInformation(extraDropOffObject)
          ]);
        } else {
          [pickUpInformation, dropOffInformation] = await Promise.all([
            getAddressInformation(pickUpObject),
            getAddressInformation(dropOffObject)
          ]);
        }
      } catch (error) {
        console.error('Error fetching address information: ', error);
        dispatch(
          openModal({
            name: 'apiError',
            properties: { error: 'Something went wrong trying to get move plan information.' }
          })
        );
        dispatch(setDnaLoading(false));
        return;
      }
    }

    const moveIntoStorage = data.moveType === 'IntoStorage';
    submitFormHandler({
      pick_up: pickUpInformation,
      drop_off: !moveIntoStorage ? dropOffInformation : '',
      extra_pick_up: '',
      extra_drop_off: !moveIntoStorage ? extraDropOffInformation ?? '' : '',
      home_size_id: HOME_SIZES_MAPPING_FROM_MM[data.addresses[0].locationSizeId],
      move_date: data.moveDate,
      move_into_storage: moveIntoStorage,
      storage_in_transit: false,
      fr_move_plan_id: frMoveId,
      fr_auth: frAuth,
      customer_name: data.custFName,
      customer_phone: data.customerCell
    });
  };

  const submitFormHandler = values => {
    const payload = {
      pick_up_address: values.pick_up,
      drop_off_address: values.drop_off,
      extra_pick_up_address: values.extra_pick_up,
      extra_drop_off_address: values.extra_drop_off,
      home_size_id: values.home_size_id,
      move_date: format(values.move_date, 'MM/DD/YYYY'),
      move_time: mapTimeToInterval(values.move_date),
      move_into_storage: values.move_into_storage,
      storage_in_transit: values.storage_in_transit,
      address_src: 'gmaps',
      extra_pick_up_enabled: false,
      extra_drop_off_enabled: Boolean(values.extra_drop_off),
      white_label_name: 'Flatrate Network',
      fr_move_plan_id: values.fr_move_plan_id,
      fr_auth: frAuth
    };
    if (!!!values.extra_pick_up) delete payload.extra_pick_up;
    if (!!!values.extra_drop_off) delete payload.extra_drop_off;
    if (values.storage_in_transit) payload.storage_move_out_date = format(values.storage_move_out_date, 'MM/DD/YYYY');
    else delete payload.storage_move_out_date;
    dispatch(submitDNA(payload));
    dispatch(
      setBookContact({
        contact_information: {
          name: values.customer_name,
          phone_number: values.customer_phone
        }
      })
    );
  };

  return (
    <div>
      <ScriptLoadContainer
        script="google"
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`}
        fallback={<FormFallback />}>
        <form onSubmit={getPlanInfoHandler} className="moveId-form">
          <input
            type="number"
            name="moveId"
            className="moveId-input"
            placeholder="Enter move id"
            defaultValue={moveid}
            required
          />
          <button disabled={dnaLoading} className="primary-btn-large h-100 w-100" type="submit">
            {dnaLoading ? (
              <ReactLoading className="m-auto" type={'spokes'} color={'#ddd'} height={30} width={30} />
            ) : (
              'Next'
            )}
          </button>
        </form>
        <style jsx>
          {`
            .moveId-form {
              display: flex;
              flex-direction: column;
              gap: 0.7em;
            }
            .moveId-input {
              width: 100%;
              min-height: 50px;
              color: #042b3b;
              border: 1px solid #d6d6d6;
              border-radius: 2px;
              padding: 10.5px 14px 10.5px 38px;
              font-size: 1rem;
              font-family: ProximaNovaRegular, sans-serif;
              font-weight: 200;
            }
            .moveId-input::-webkit-outer-spin-button,
            .moveId-input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            /* Firefox */
            .moveId-input[type='number'] {
              -moz-appearance: textfield;
            }
          `}
        </style>
      </ScriptLoadContainer>
    </div>
  );
};

export default DnaFormSalesRep;

const FormFallback = () => {
  return (
    <>
      <form className="moveId-form">
        <input type="number" name="moveId" required className="moveId-input" placeholder="Enter move id" />
        <button disabled={true} className="primary-btn-large h-100 w-100 btn-submit-form" type="submit">
          <ReactLoading className="m-auto" type={'spokes'} color={'#ddd'} height={30} width={30} />
        </button>
      </form>
      <style jsx>
        {`
          .btn-submit-form {
            font-size: 1.4rem;
            font-family: ProximaNovaBold, sans-serif;
          }
          .moveId-form {
            display: flex;
            flex-direction: column;
            gap: 0.7em;
          }
          .moveId-input {
            width: 100%;
            min-height: 50px;
            color: #042b3b;
            border: 1px solid #d6d6d6;
            border-radius: 2px;
            padding: 10.5px 14px 10.5px 38px;
            font-size: 1rem;
            font-family: ProximaNovaRegular, sans-serif;
            font-weight: 200;
          }
          .moveId-input::-webkit-outer-spin-button,
          .moveId-input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          .moveId-input[type='number'] {
            -moz-appearance: textfield;
          }
        `}
      </style>
    </>
  );
};
