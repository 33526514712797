import React from 'react';
//import ServicesDropdown from './ServicesDropdown';
import { openModal } from '../../../redux/actions/modals';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class NavLeft extends React.Component {
  constructor(props) {
    super(props);
    this.state = { servicesOpen: false };
  }

  handleServicesDropdownOpen = () => {
    this.setState({ servicesOpen: true });
  };

  handleServicesDropdownClose = () => {
    this.setState({ servicesOpen: false });
  };

  render() {
    return (
      <div className="proxima nav-left h-100">
        <ul className="list-inline pull-left mb-0">
          <Link to="/" className="logo-container pr-2 pull-left d-inline-flex float-left">
            <img
              alt="Flatrate Network Logo"
              className="logo"
              width="70px"
              height="70px"
              src={`${process.env.REACT_APP_CDN_IMAGES}/flatrateNetworkLogo70x70.jpg`}
            />
          </Link>
          {/* <li className="list-inline-item text-white menu-item">
            <Link to="/what-is-flatrate/" className="menu-item-link">
              About Us
            </Link>
          </li> */}
          {/* <li className="list-inline-item text-white menu-item">
            <Link to="/how-it-works/" className="menu-item-link">
              How It Works
            </Link>
          </li> */}
          {/* <li className="list-inline-item text-white menu-item">
            <Link to="/faq/" className="menu-item-link">
              FAQ
            </Link>
          </li> */}
          {/* <li
            className="list-inline-item menu-item"
            onMouseEnter={this.handleServicesDropdownOpen}
            onMouseLeave={this.handleServicesDropdownClose}
            open={this.state.servicesOpen}>
            <Link to="/moving-services/" className="menu-item-link text-white">
              Services
            </Link>
            {this.state.servicesOpen && <ServicesDropdown />}
          </li> */}
          <li className="list-inline-item text-white menu-item">
            <Link to="/customer-service/" className="menu-item-link text-white">
              Contact
            </Link>
          </li>
          {/* <li className="list-inline-item text-white menu-item">
            <a href="https://blog.flatrate.com/" className="menu-item-link text-white">
              Blog
            </a>
          </li> */}
          {/* <li className="list-inline-item text-white menu-item">
            <Link className="menu-item-link flatrate-network-red" to="/benefits/">
              Get $20
            </Link>
          </li>  */}
        </ul>
        <style jsx>{`
          .logo {
            fill: white;
            height: 70px;
            margin-right: 10px;
          }
          .logo-container {
            height: 55px;
            cursor: pointer;
          }
          :global(.nav-left .menu-item-link) {
            padding: 0 8px 0 8px;
            text-decoration: none;
            color: white;
            height: 60px;
            display: block;
            margin-top: 3px;
            cursor: pointer;
          }
          :global(.nav-left .menu-item a:hover),
          :global(.nav-left .menu-item span:hover) {
            color: #e23337 !important;
          }
          .active {
            color: #e23337 !important;
          }
          .how-it-works-modal {
            width: 95% !important;
            top: 10% !important;
            max-width: 960px !important;
          }
          @media (max-width: 1005px) {
            .list-inline-item {
              margin-right: 0.3rem;
            }
          }
        `}</style>
      </div>
    );
  }
}
export default connect(null, { openModal })(NavLeft);
